import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';

import { IUserAccessInfo, UserDetailsService } from '../../core/services/user-details.service';
import { RolesService } from '../../core/services/roles.service';

@Injectable()

export class CanActivateManageService implements CanActivate {

    constructor(
        private userDetailsService: UserDetailsService,
        private rolesService: RolesService
    ) { }

    public canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const hubId = currentRoute.parent.params['hub_id'];
        const userDetails: IUserAccessInfo = this.userDetailsService.userAccessInfo();
        return this.rolesService.readRolesForUser(hubId).toPromise().then(roles => {
            return userDetails.user_account.org_admin || userDetails.user_account.super_admin || userDetails.isCanCreateAlert || !!roles['hub_customization'];
        });
    }
}
