export class ChatEventsConstants {
    public static readonly CREATE: string = '/chat@create';
    public static readonly READ: string = '/chat@read';
    public static readonly UPDATE: string = '/chat@update';
    public static readonly REMOVE: string = '/chat@remove';
    public static readonly ENTER: string = '/chat@enter';
    public static readonly EXIT: string = '/chat@exit';
    public static readonly CLEAR_HISTORY: string = '/chat@clear_history';
    public static readonly LEAVE_GROUP: string = '/chat@group@leave';
    public static readonly SUBSCRIBE: string = '/chat@stream@subscribe';
    public static readonly UNSUBSCRIBE: string = '/chat@stream@unsubscribe';
    public static readonly REMOVE_STREAM: string = '/chat@remove@stream';
    public static readonly REMOVE_ALERT: string = '/chat@notification@remove';
    public static readonly REMOVE_LOCAL_ALERT: string = '/chat@notification@remove_local';
    public static readonly ACKNOWLEDGE: string = '/chat@acknowledge@notification';
}
