import {takeWhile, filter, switchMap, map, distinctUntilChanged, catchError} from 'rxjs/operators';
import { Observable ,  Subject } from 'rxjs';
import { FayeService } from './../../core/services/faye.service';
import { HttpMiddleService } from './../../core/services/http-middle.service';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';

import { UserDetailsService, IUserAccessInfo } from '../../core/services/user-details.service';
import { IUnreadCount } from '../../core/services/chats.service';
import {error} from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class BadgeCountService implements OnDestroy {

  private _currentUser: IUserAccessInfo;
  private _updateBadgeCountAnnouncedSource = new Subject<{unread: IBadgeCount, unreadChats: any}>();
  public updateadgeCountAnnounced$ = this._updateBadgeCountAnnouncedSource.asObservable();
  private _unreadChats: IUnreadCount[] = [];
  private _updateBadgeCountListner = new Subject();
  private _alive = true;

  constructor(private http: HttpMiddleService,
              private fayeService: FayeService,
              private userDetailsService: UserDetailsService) {
    // lisen for faye reconnect and call unread recount
    this.fayeService.clientIsOnlineSource.pipe(
      takeWhile(() => this._alive)).subscribe(() => {
      this.updateBadgeCount();
    });

    // lisen for udpdate unread count call
    this._updateBadgeCountListner.pipe(
      takeWhile(() => this._alive),
      // if user account exist and user hase organizations
      filter(() => {
        this._currentUser = this.userDetailsService.userAccessInfo();
        const userAccount = this._currentUser.user_account;
        return (userAccount && userAccount.organizations && userAccount.organizations.length && !userAccount.super_admin);
      }),
      switchMap(() => this.getUnreadMessagesCount(this._currentUser.user_account.organizations[0]._id)),
      distinctUntilChanged(),
      map(unreadChats => {
        this._unreadChats = unreadChats;
        const unread = { 'chats': 0, 'streams': 0, 'notifications': 0 };
        unreadChats.forEach(chat => {
          if (chat.chat_type === 'stream') {
            unread.streams += chat.unread;
          } else if (chat.chat_type === 'notification') {
            unread.notifications += chat.unread;
          } else {
            unread.chats += chat.unread;
          }
      });
        return { unread, unreadChats };
      }),
    ).subscribe(unread => {
      this._updateBadgeCountAnnouncedSource.next(unread);
    });
  }

  get unreadChats() {
    return this._unreadChats;
  }

  public updateBadgeCount() {
    this._updateBadgeCountListner.next();
  }

  public getUnreadMessagesCount(organization_id): Observable<IUnreadCount[]> {
    const data = { organization_id, read_chats: []};
    return this.http.post(`${environment.API_HTTP_URL}chewbacca/communicate/messages/count/unread`, data).pipe(map((response: any) => {
      const res = response;
      return res.messages_count ? res.messages_count : [];
    }));
  }

  ngOnDestroy(): void {
   this._alive = false;
  }

}

export interface IBadgeCount {
  chats?: number;
  streams?: number;
  notifications?: number;
}
