import { AfterContentInit, Component, HostListener } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart } from '@angular/router';

import { Subject } from 'rxjs/index';

import { AuthorizationService } from '../app/core/services/authorization.service';
import { FayeService } from '../app/core/services/faye.service';
import { SecurityService } from '../app/core/services/security.service';
import { RouterExtService } from './core/services/router-ext-service.service';

import { CommonEventsConstants } from '../app/constants/common-events.constants';
import { routerTransition } from './core/shared/animations/router-transition';

const _userAgent = window.navigator.userAgent,
  onlyIEorEdge = /msie\s|trident\/|edge\//i.test(_userAgent) && !!(document['uniqueID'] || window['MSInputMethodContext']),
  checkVersion = (onlyIEorEdge && +(/(edge\/|rv:|msie\s)([\d.]+)/i.exec(_userAgent)[2])) || NaN;
if (!isNaN(checkVersion)) {
  if (checkVersion < 12) {
    const content =
      '<div class="loader-main">' +
      '<div class="loader-header"></div>' +
      '<div class="loader-conteiner">' +
      '<div class="hub-icon">' +
      '<img src="./assets/icons/hub_icon.png" alt="">' +
      '</div>' +
      '<div class="text">' +
      '<div class="hub-phrase">The Hub is built with the latest technologies,' +
      '<br> please update your IE to the latest version of Edge <br>' +
      'or use Google Chrome or Firefox.</div>' +
      '</div>' +
      '</div>' +
      '<footer class="loader-footer">' +
      'Copyright © <script>document.write(new Date().getFullYear());</script> Hubspring Health LLC' +
      '</footer>' +
      '</div>;';
    document.getElementsByTagName('body')[0].innerHTML = content;
  }
}

declare var Notification: any;



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  animations: [ routerTransition ],
})
export class AppComponent implements AfterContentInit {

  loading$: Subject<boolean>;

  @HostListener('window:unload', ['$event'])
  unloadHandler(_event) {
    this.securityService.wasRefreshed = Date.now();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(_event) {
    this.securityService.wasRefreshed = Date.now();
  }

  @HostListener('document:mousedown', ['$event'])
  onMousedown(_event) {
    this.securityService.setLastActivity();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(_event) {
    this.securityService.setLastActivity();
  }

  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange(_event) {
    this.securityService.setLastActivity();
  }
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private fayeService: FayeService,
    private securityService: SecurityService,
    private routerExtService: RouterExtService
  ) {
    this.loading$ = this.routerExtService.loading;
  }

  ngAfterContentInit(): void {
    if (Notification) {
      if (['granted', 'denied'].indexOf(Notification.permission) === -1) {
        Notification.requestPermission(() => { });
      }
    }
    this.securityService.isRefreshed();
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    const client = this.fayeService.getClient();
    if (client) {
      client.subscribe(CommonEventsConstants.REMOVE_USER, () => this.authorizationService.logout());
    }
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  // RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.authorizationService.isLogged();
    }
  }

}
