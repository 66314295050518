export class PatternConstants {
   public static readonly EMAIL:                RegExp = /^[a-zA-Z0-9]+[_a-zA-Z0-9\.-]*[a-zA-Z0-9]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})/;
   public static readonly TELEPHONE:            RegExp = /^\+(\d+)\s(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{3,4}|(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/;
   public static readonly WEBSITE:              RegExp = /(^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\.]{1,6})([\/\w \.-]))|(^(https?:\/\/)jetportal)/;
   public static readonly PASSWORD:             RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\s!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~])(?=.{8,})/;
   public static readonly ZIPCODE:              RegExp = /^\d{5}$/;
   public static readonly NAME:                 RegExp = /^[a-zA-Z.-]*$/;
   public static readonly NICKNAME:             RegExp = /^[a-zA-Z_.-]*$/;

   public static readonly FILE_EXTENSIONS:      RegExp = /(gif|jpeg?|png|jpg|mpeg|mp3|wav|docx?|pptx?|xlsx?|mp4|ogg|ogv|qt|flv|3gp|3g2|avi|mov|pdf|potx|ppsx|rtf|txt)/g;
   public static readonly PHONE_NUMBER_MASKS:   IMasks = {
        phone:              ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        phoneOrCountry:     ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/],
        phoneCountryCode:   ['+', /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        zip:                [/[0-9]/, /\d/, /\d/, /\d/, /\d/]
   };

}

interface IMasks {
    phone:              any;
    phoneOrCountry:     any;
    phoneCountryCode:   any;
    zip:                any;
}
