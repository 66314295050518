
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Subject ,  Observable } from 'rxjs';

import * as moment from 'moment';
import { findIndex } from 'lodash';

import { environment } from '../../../environments/environment';
import { MessageEventsConstants } from '../../constants/message-events.constants';
import { HttpMiddleService } from './http-middle.service';
import { IRecipient, IChat } from './chats.service';
import { FayeService } from './faye.service';
import { BadgeCountService } from '../../dashboard/hub-comm-nav-bar/badge-count.service';
import HubComFullName from '../utils/hub-com-full-name';

@Injectable()
export class MessagesService {

    public replyToMessageSource$: Observable<any>;
    private replyToMessageSource: Subject<any> = new Subject();

    constructor(
        private http: HttpMiddleService,
        private fayeService: FayeService,
        private badgeCountService: BadgeCountService
    ) {
        this.replyToMessageSource$ = this.replyToMessageSource.asObservable();
    }

    public addRepliedMessageAuthor(recipients, message) {
        const index = findIndex(recipients, { _id: message.reply_to_creator_id });
        if (index !== -1) {
            message['reply_to_creator_name'] = HubComFullName.get(recipients[index]);
        }
    }

    public onReplyToMessage(data) {
        this.replyToMessageSource.next(data);
    }

    // HTTP
    public sync(chat_id: number,
        chat_type: string,
        is_favorite = false,
        last_update?: number,
        limit: number = 40,
        is_web: boolean = true,
        unsubscribed_stream?: boolean): Observable<IMessages> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/communicate/messages/sync`, {
            chat_id,
            chat_type,
            is_web,
            last_update,
            limit,
            is_favorite,
            unsubscribed_stream
        }).pipe(map((response: any) => {
            const messages = response;
            for (const message of messages.messages) {
                message.created_at = moment(message.created_at).format();
                message.updated_at = moment(message.updated_at).format();
            }
            return <IMessages>messages;
        }));
    }


    public pageMessages(last_update: string,
        chat_id: number,
        chat_type: string,
        is_favorite = true,
        limit: number = 20,
        is_web: boolean = true,
        unsubscribed_stream?: boolean): Observable<IMessages> {
        last_update = last_update ?
            new Date(last_update).toISOString().slice(0, 19).replace('T', ' ') :
            new Date().toISOString().slice(0, 19).replace('T', ' ');
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/communicate/messages/page`, {
            chat_id,
            chat_type,
            is_web,
            last_update,
            limit,
            is_favorite,
            unsubscribed_stream
        }).pipe(map((messages: any) => {
            return messages;
        }));
    }

    public getMessageStatus(messageId: number) {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/communicate/read/message/${messageId}/status`).pipe(
        map(messageStatus => {
                return messageStatus;
            }));
    }

    // Socket Emits

    public create(msgData: Object): Observable<INewMessage> {
        if (msgData['file'] && msgData['file'].source !== 'link') {
            return this.http.upload(`${environment.API_SOCKET_URL}socket/message/create`,
            [msgData['file']], msgData).pipe(map((res: any) => {
                if (res.attachment) {
                    res.attachment.created_at = moment(res.attachment.created_at).format();
                    res.attachment.updated_at = moment(res.attachment.updated_at).format();
                }
                res.message.created_at = moment(res.message.created_at).format();
                res.message.updated_at = moment(res.message.updated_at).format();
                return res as INewMessage;
            }));
        }
        return this.http.post(`${environment.API_SOCKET_URL}socket/message/create`, msgData).pipe(map((response: any) => {
            const res = response;
            if (res.attachment) {
                res.attachment.created_at = moment(res.attachment.created_at).format();
                res.attachment.updated_at = moment(res.attachment.updated_at).format();
            }
            res.message.created_at = moment(res.message.created_at).format();
            res.message.updated_at = moment(res.message.updated_at).format();
            return res as INewMessage;
        }));
    }

    public remove(msgId: number): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/message/remove`, { message_id: msgId }).pipe(map((res: any) => {
            return res;
        }));
    }

    public delivered(msgId: number): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/message/delivered`, { message_id: msgId }).pipe(map((res: any) => {
            return res;
        }));
    }

    // Faye Events Listeners

    public onCreate(): Observable<INewMessageWithChat> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(MessageEventsConstants.CREATE, (message) => {
                this.badgeCountService.updateBadgeCount();
                message.message.created_at = moment(message.message.created_at).format();
                message.message.updated_at = moment(message.message.updated_at).format();
                observer.next(message);
            });
        });
    }

    public onSeen(): Observable<ISeenMessage> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(MessageEventsConstants.SEEN, data => {
                observer.next(data);
            });
        });
    }

    public onDelivered(): Observable<IDeliveredMessage> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(MessageEventsConstants.DELIVERED, data => {
                observer.next(data);
            });
        });
    }

    public onRemoved(): Observable<IRemovedMessage> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(MessageEventsConstants.REMOVE, data => {
                observer.next(data);
            });
        });
    }

    public onTyping(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(MessageEventsConstants.TYPING, (message) => {
                observer.next(message);
            });
        });
    }

}

export interface IMessage {
    id?: number;
    temp_id?: number;
    chat_id: number;
    chat_type: 'direct' | 'group' | 'role' | 'stream' | 'notification';
    creator: IRecipient;
    creator_id: string;
    is_delivered: null | boolean;
    is_seen: null | boolean;
    is_sent: null | boolean;
    is_error: null | boolean;
    message: string;
    attachment?: any;
    file?: any;
    fileInfo?: any;
    created_at: string;
    updated_at: string;
    reply_to?: number;
    reply_to_creator_id?: string;
    icon?: string;
    title?: string;
}

export interface IMessages {
    messages: IMessage[];
}

export interface INewMessage {
    error?: any;
    creator: IRecipient;
    attachment?: any;
    message: IMessage;
}

export interface INewMessageWithChat {
    creator: IRecipient;
    attachment?: any;
    message: IMessage;
    chat: IChat;
    recipients?: string[];
}

export interface IDeliveredMessage {
    chat_id: number;
    chat_type: 'direct' | 'group' | 'role' | 'stream' | 'notification';
    is_delivered: string;
    message_id: number;
    updated_at: string;
}

export interface ISeenMessage {
    is_seen: string;
    message_id: number;
    updated_at: string;
    seen_by?: string;
}

export interface IRemovedMessage {
    message_id: number;
    chat_id: number;
    temp_id: number;
    user_id: string;
}
