export class CommonEventsConstants {
    public static readonly REMOVE_USER:          string = '/user@remove@profile';
    public static readonly REMOVE_USER_FROM_HUB: string = '/user@remove@from@hub';
    public static readonly HUBCOM_STATUS:        string = '/chat@communicate@status';
    public static readonly HUB_ENTER:            string = '/hub@enter';
    public static readonly HUB_EXIT:             string = '/hub@exit';
    public static readonly USER_ONLINE:          string = '/user@online';
    public static readonly USER_OFFLINE:         string = '/user@offline';
    public static readonly ME_ONLINE:            string = '/me@online';
};
