
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';
import { LocalStorageService } from './local-storage.service';
import { SecurityService } from './security.service';
import { SessionStorageService } from './session-storage.service';
import { FayeService } from '../services/faye.service';
import { AnalyticsService } from '../services/analytics.service';
import { UserDetailsService } from './user-details.service';
import { PatternConstants } from './../../constants/pattern.constants';


export interface Login {
    login: string;
    password: string;
    is_web?: boolean;
}

export interface INewPassword {
    token: string;
    newPassword: string;
    confirmPassword: string;
}

export interface IResetPassword {
    login: string;
    password: string;
    uuid: string;
    confirmPassword?: string;
}

interface IForgot {
    login: string;
    host: string;
}

interface IAuth0Login {
    login: string;
    token: string;
    auth0_id: string;
    is_web?: boolean;
}

@Injectable()
export class AuthorizationService implements CanActivate {
    password_pattern: RegExp = PatternConstants.PASSWORD;

    constructor(
        private http: HttpMiddleService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private userDetailsService: UserDetailsService,
        private fayeService: FayeService,
        private analyticsService: AnalyticsService,
        private securityService: SecurityService
    ) { }

    public auth(data: any): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}kenobi/auth`, data).pipe(map(res => {
            return res;
        }));
    }
    public login(data: Login, loginAny = ''): Observable<any> {

        return this.http.post(`${environment.API_HTTP_URL}kenobi/login`, data).pipe(map((res: any) => {
            const responseJson = res;
            this.sessionStorageService.setObject('access_info', responseJson);
            if (responseJson && responseJson.user_account && responseJson.user_account._id) {
                this.analyticsService.createEvent({
                    event_type: 'login',
                    resource_id: responseJson.user_account._id,
                    resource_type: 'user'
                });
            }
            this.sessionStorageService.set('isSSO', loginAny);
            this.fayeService.connect().catch(err => {
                console.log(`FAYE :: ${err}`);
            });
            return responseJson;
        }));
    }

    public loginSSO(data: IAuth0Login): Observable<any> {
        this.sessionStorageService.setObject('access_info', { access_token: data.token });
        return this.http.post(`${environment.API_HTTP_URL}kenobi/authlogin`, {
            login: data.login,
            auth0_id: data.auth0_id,
            is_web: !!data.is_web
        }).pipe(map((res: any) => {
            const responseJson = res;
            if (responseJson && responseJson.user_account && responseJson.user_account._id) {
                setTimeout(() => {
                    this.analyticsService.createEvent({
                        event_type: 'login',
                        resource_id: responseJson.user_account._id,
                        resource_type: 'user'
                    });

                }, 200);
            }
            this.sessionStorageService.set('isSSO', 'true');
            this.sessionStorageService.setObject('access_info', responseJson);
            this.fayeService.connect().catch(err => {
                console.log(`FAYE :: ${err}`);
            });
            return responseJson;
        }));
    }

    public getSSOData(): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}kenobi/auth0config`).pipe(map(res => res));
    }

    public forgot(data: IForgot): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}kenobi/password/forget_web`, data).pipe(map(res => {
            const responseJson = res;
            return responseJson;
        }));
    }

    public reset(data: INewPassword): Observable<any> {
        return this.http.put(`${environment.API_HTTP_URL}kenobi/password/reset_web`, data).pipe(map(res => {
            return res;
        }));
    }

    public setResetPasswordTimeSource(token: string): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}kenobi/reset/password/start`, { token }).pipe(map(res => {
            return res;
        }));
    }

    public resetDefaultPassword(data: IResetPassword): Observable<any> {
        return this.http.put(`${environment.API_HTTP_URL}kenobi/password/forget`, data).pipe(map(res => {
            return res;
        }));
    }

    public logout() {
        this.fayeService.disconnect().then(_res => {
            const userAccount = this.sessionStorageService.getObject('access_info');
            if (userAccount && userAccount.user_account && userAccount.user_account._id) {
                this.analyticsService.createEvent({
                    event_type: 'logout',
                    resource_id: userAccount.user_account._id,
                    resource_type: 'user'
                });
            }
            if (this.localStorageService.getObject('access_info').loginSSO) {
                const ref = window.open(`https://hubspring.auth0.com/v2/logout?` +
                    `federated&returnTo=${environment.HOST_NAME.slice(0, -3)}&client_id=bubddeGs45cQV5h6I7U1wVePstaL3fwh`,
                    `_blank`, `clearcache=yes`);
                setTimeout(() => {
                    ref.close();
                }, 5000);
                localStorage.clear();
                this.sessionStorageService.clear();
                this.router.navigate(['/']);
            } else {
                localStorage.clear();
                this.sessionStorageService.clear();
                this.router.navigate(['/']);
            }
            this.securityService.claerIntervals();
        });
    }

    public isLogged(): boolean {
        const userAccount = this.userDetailsService.userAccessInfo();
        return !!(userAccount && userAccount.user_account);
    }

    private ifCanActivate(route?: ActivatedRouteSnapshot) {
        if (this.userDetailsService.isSuperAdmin()) {
            return true;
        }
        if (this.userDetailsService.isDefaultPassword() && route.data.stateName === 'reset_default_password') {
            return true;
        }
        if (this.userDetailsService.isDefaultPassword() && route.data.stateName !== 'reset_default_password') {
            this.router.navigate(['reset-default-password']);
            return false;
        }
        if (!this.userDetailsService.isDefaultPassword() && route.data.stateName === 'reset_default_password') {
            this.router.navigate(['/']);
            return false;
        }
        let { currentOrganization, currentHub } = this.getDataFromRoute(route);
        if (currentOrganization && currentHub) {
            if (!this.userDetailsService.isUserInOrganisation(currentOrganization)) return false;
            if (!this.userDetailsService.isUserInHub(currentHub)) return false;
            return true;
        } else {
            return false;
        }
    }
    private getDataFromRoute(route: ActivatedRouteSnapshot): { currentOrganization: string, currentHub: string } {
        return (route && route.params && route.params.organization_id) ?
            {
                currentOrganization: route.params.organization_id,
                currentHub: route.params.hub_id
            }
            :
            this.getDataFromRoute(route.parent);
    }
    canActivate(route: ActivatedRouteSnapshot) {
        if (!this.isLogged()) {
            this.router.navigate(['/']);
            return false;
        }
        if (!this.ifCanActivate(route)) {
            this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }

    public getRegistrationForm() {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/registration-form`).pipe(map(res => {
            return res;
        }));
    }

    public registration(data: any): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}yoda/user/create/self`, { userData: data, host: environment.HOST_NAME }).pipe(map(res => {
            return res;
        }));
    }
}
