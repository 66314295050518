export default class HubComFullName {
    static get(data: IHubComFullName, morpheme?: boolean) {
        let fullName = data.last_name ? data.last_name : '';
        if (data.first_name) {
            fullName = data.first_name + ' ' + fullName;
        }

        if (morpheme) {
            if (data.prefix && data.prefix.trim()) {
                fullName = data.prefix + ' ' + fullName;
            }
            if (data.suffix && data.suffix.trim()) {
                fullName = fullName + ', ' + data.suffix;
            }
        }

        return fullName;
    }
}

export interface IHubComFullName {
    last_name:   string,
    first_name?: string,
    prefix?:     string,
    suffix?:     string
}

