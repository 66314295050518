import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate } from '@angular/router';
import { environment } from '../../environments/environment';
import { URLSearchParams } from '@angular/http';
import { AuthorizationService } from '../core/services/authorization.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { SessionStorageService } from '../core/services/session-storage.service';
import { Auth0Service } from '../core/services/auth0.service';

@Injectable()
export class Auth0SkipGuard implements CanActivate {
    constructor(
        private location: Location,
        private router: Router,
        private authorizationService: AuthorizationService,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private auth0Service: Auth0Service
    ) {}

    canActivate() {
        const index = this.location.path(true).indexOf('access_token');
        if (index === -1) {
            return true;
        } else {
            const params = new URLSearchParams(this.location.path(true)),
                access_token = params.get('access_token'),
                token = params.get('id_token'),
                login = this.localStorageService.getObject('access_info').loginSSO;
            if (token && access_token && login) {
                this.auth0Service.verify(access_token, login).then((auth0_id) => {
                    if (auth0_id) {
                       this.authorizationService.loginSSO({ login, token, auth0_id, is_web: true }).subscribe(() => {
                            this.router.navigate(['/']);
                        }, () => {
                            const ref = window.open(`https://hubspring.auth0.com/v2/logout?` +
                                `federated&returnTo=${environment.HOST_NAME.slice(0, -3)}&client_id=bubddeGs45cQV5h6I7U1wVePstaL3fwh`,
                                `_blank`, `clearcache=yes`);
                                setTimeout( () => {
                                    ref.close();
                                }, 5000);
                            localStorage.clear();
                            this.sessionStorageService.clear();
                            this.localStorageService.set('auth0_error', 'true');
                            this.router.navigate(['/']);
                        });
                    } else {
                        this.router.navigate(['/']);
                    }
                }, () => {
                    this.router.navigate(['/']);
                });
            } else {
                return true;
            }
        }
    }
}
