import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';
import { IProfile } from './profile.service';
import { Observable } from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';


@Injectable()
export class UsersService {
    constructor(private http: HttpMiddleService,
                private httpClient: HttpClient) {}

    public setHttpParams(reqOptions: any): HttpParams {
        let params = new HttpParams();
        if (!reqOptions) {
            return params;
        }

        for (const key of Object.keys(reqOptions)) {
            if (reqOptions[key]) {
                params = params.set(key, reqOptions[key]);
            }
        }
        return params;
    }

    public readUsers(orgId: string, type?): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}yoda/user/organization/${orgId}/all?type=${type}`).pipe(map((users: any) => {
            return users;
        }));
    }

    public readUsersInRole(orgId: string, body) {
        const params = this.setHttpParams(body);
        return this.httpClient.get(`${environment.API_HTTP_URL}yoda/user/organization/${orgId}`, {params}).pipe(map((users: any) => {
            return users;
        }));
    }

    public readUsersInOrg(orgId, body) {
        const params = this.setHttpParams(body);
        return this.httpClient.get(`${environment.API_HTTP_URL}yoda/organization/${orgId}/search?`, {params}).pipe(map((users: any) => {
            return users;
        }));
    }

    public getUser(id): Observable<any> {
        return this.httpClient.get(`${environment.API_HTTP_URL}kenobi/user/${id}`, );
    }

}
