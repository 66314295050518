import {map} from 'rxjs/operators';
import { Injectable }               from '@angular/core';
import { environment }              from '../../../environments/environment';
import { HttpMiddleService }        from './http-middle.service';
import { SessionStorageService }    from './session-storage.service';
import { IPermissions }             from './roles.service';
import { Observable }               from 'rxjs';

@Injectable()
export class HubsService {
    constructor(private http: HttpMiddleService,
        private sessionStorageService: SessionStorageService) { }

    public get(organizationId: string): Observable<IHub[]> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/hub`, {organization_id: organizationId}).pipe(map((res: any) => {
            const accessInfo = this.sessionStorageService.getObject('access_info');
            if (accessInfo.user_account.super_admin || accessInfo.user_account.org_admin) {
                accessInfo.hubs = res;
                this.sessionStorageService.setObject('access_info', accessInfo);
            }
            return res;
        }));
    }

    public getOne(organizationId: string, hubId: string): Observable<IHub> {
        return this.http.get(
            `${environment.API_HTTP_URL}chewbacca/hub/${hubId}`,
            { organization_id: organizationId }).pipe(map((res: any) => {
                return res;
            }));
    }

    public isCommunicate(organizationId: string, hubId: string): Observable<boolean> {
        return this.http.get(
                `${environment.API_HTTP_URL}chewbacca/hub/${hubId}`,
                { organization_id: organizationId }
            ).pipe(map((res: any) => {
            return !!res['isCommunicate'];
        }));
    }

    public delete(hubId: string): Observable<any> {
        return this.http.delete(`${environment.API_HTTP_URL}chewbacca/hub/${hubId}`).pipe(map((res: any) => {
            return res;
        }));
    }

    public create(data: INewHub): Observable<IHub> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/hub`, data).pipe(map((res: any) => {
            return res;
        }));
    }

    public update(data: INewHub, id: string): Observable<IHub> {
        return this.http.put(`${environment.API_HTTP_URL}chewbacca/hub/${id}`, data).pipe(map((res: any) => {
            return res;
        }));
    }

}

export interface INewHub {
    description?:       string;
    footer_color:       string;
    white_text_mode:         boolean;
    header_color:       string;
    isCommunicate:      boolean;
    name:               string;
    organization_id:    string;
    background?:        string;
    banner?:            string;
    screen?:            string;
    icon?:              string;
    viewType:           '4 Column Palette' | '3 Column Palette' | '2 Column Palette' | 'List';
}

export interface IHub {
    _id:                string;
    alerts?:            any;
    description?:       string;
    footer_color:       string;
    header_color:       string;
    white_text_mode:         boolean;
    isCommunicate:      boolean;
    max_chats?:         number;
    name:               string;
    organization_id:    string;
    role?:              IPermissions[];
    user_id?:           string;
    background?:        string;
    banner?:            string;
    screen?:            string;
    icon?:              string;
    viewType:           '4 Column Palette' | '3 Column Palette' | '2 Column Palette' | 'List';
    createdAt:          string;
    updatedAt:          string;
}
