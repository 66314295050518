import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { Auth0SkipGuard } from './login/auth0-canActivate';
import { AuthorizationService } from './core/services';

const routes: Routes = [
  {
    path: 'not-found',
    loadChildren: () => import('./page-not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'mode/superadmin',
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
    canActivate: [AuthorizationService],
  },
  {
    path: 'dashboard/organization/:organization_id/hub/:hub_id',
    loadChildren: () => import('./dashboard/layout.module').then((m) => m.LayoutModule),
    data: { stateName: 'dashboard_root' },
    canActivate: [AuthorizationService],
  },
  {
    path: 'reset-default-password',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    canActivate: [AuthorizationService],
    data: { stateName: 'reset_default_password' }
  },
  {
    path: '',
    loadChildren: () => import('./login/auth.module').then((m) => m.AuthModule),
    canActivate: [Auth0SkipGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  // providers: [AuthorizationService, AnalyticsService]
})
export class AppRoutingModule { }
