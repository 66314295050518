import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ConfirmModalComponent } from './confirm-modal.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor(
    private dialog: MatDialog
  ) { }

  public openDialog(data: any, config: MatDialogConfig = {}, isAlert: boolean = false): Observable<boolean> {
    data['isAlert'] = isAlert;
    config['data'] = data;
    const dialogRef = this.dialog.open(ConfirmModalComponent, config);

    return dialogRef.afterClosed();
  }

}
