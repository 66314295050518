import { environment } from 'environments/environment';
// Angular libs //
import { ErrorHandler, NgModule, Injectable, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Services //
import {AttachmentPreviewService, HttpMiddleService, SecurityService} from './core/services';
import { AuthorizationService } from './core/services';
import { FayeService } from './core/services';
import { UserDetailsService } from './core/services';
import { HubsService } from './core/services';
import { RolesService } from './core/services';
import { ChatsService } from './core/services';

// Guards //
import { CanActivateHubComService } from './core/services/can-activate-hub-com.service';
import { CanActivateManageService } from './dashboard/manage/can-activate-manage.service';
import { CanDeactivateSelectRolesComponent } from './dashboard/nodes-view/nodes-manage/select-roles/can-deactivate-select-roles';
import { Auth0SkipGuard } from './login/auth0-canActivate';
import { CanDeactivateCreateNodes } from './dashboard/nodes-view/nodes-manage/create-nodes/can-deactivate-create-nodes';

// Components //
import { AppComponent } from './app.component';

// Interceptors //
import { AuthInterceptor } from './core/shared/auth.interceptor';
import { ErrorInterceptor } from './core/shared/error.interceptor';

// Modules //
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './core/shared/shared.module';

// Sentry
// comment this module until figure out how use together with sentry
// import { CustomErrorHandler } from './core/services/custom-error-handler.service';
import * as Sentry from '@sentry/browser';
import { Router } from '@angular/router';
import {CoreModule} from './core/core.module';
import {AnalyticsService} from './core/services';

Sentry.init({
  dsn: 'https://a2e31c0e9e5948bd93ea2df65ca0cec9@sentry.s-pro.io/13',
  environment: environment.envName
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error) {
    if (error instanceof URIError && error.name === 'URIError' && error.message === 'URI malformed') {
      try {
        this.injector.get(Router).navigate(['not-found']);
      } catch (error) {
        Sentry.captureException(error.originalError || error);
      }
    } else {
      Sentry.captureException(error.originalError || error);
    }
    // uncomment to show Report dialog
    // Sentry.showReportDialog({ eventId });
  }
}

const materialModules = [
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    // TextMaskModule,
    // ClipboardModule,
    NgbModule,
    // SharedModule,
    ...materialModules,
    // MatPasswordStrengthModule.forRoot(),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthorizationService,
    // ChatsService,
    CanActivateHubComService,
    FayeService,
    HttpMiddleService,
    UserDetailsService,
    HubsService,
    CanDeactivateSelectRolesComponent,
    Auth0SkipGuard,
    CookieService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    CanActivateManageService,
    RolesService,
    CanDeactivateCreateNodes,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AnalyticsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
