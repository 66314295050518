import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';

@Injectable({
    providedIn: 'root'
})
export class ScheduledService {
    constructor(private http: HttpMiddleService) {}

    public getScheduledEvents(): Observable<any[]> {
        return this.http.get(`${environment.API_HTTP_URL}/chewbacca/scheduled`).pipe(
            map((events: any) => {
                return events;
            })
        );
    }

    public getScheduledEvent(eventId: number): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}/chewbacca/scheduled/${eventId}`).pipe(
            map((event: any) => {
                return event;
            })
        );
    }

    public createEvent(data: any): Observable<any[]> {
        if (data['file'] && data['file'].source !== 'link') {
            return this.http.upload(`${environment.API_HTTP_URL}/chewbacca/scheduled`, [data['file']], data).pipe(
                map((res: any) => {
                    return res;
                })
            );
        }
        return this.http.post(`${environment.API_HTTP_URL}/chewbacca/scheduled`, data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateEvent(eventId: number, data: any): Observable<any[]> {
        if (data['file'] && data['file'].source !== 'link') {
            return this.http.upload(`${environment.API_HTTP_URL}/chewbacca/scheduled/${eventId}`, [data['file']], data).pipe(
                map((res: any) => {
                    return res;
                })
            );
        }
        return this.http.post(`${environment.API_HTTP_URL}/chewbacca/scheduled/${eventId}`, data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteEvent(eventId: number): Observable<any[]> {
        return this.http.delete(`${environment.API_HTTP_URL}/chewbacca/scheduled/${eventId}`).pipe(
            map((events: any) => {
                return events;
            })
        );
    }
    public deleteAttachmentEvent(eventId: number): Observable<any[]> {
        return this.http.delete(`${environment.API_HTTP_URL}chewbacca/scheduled/attachment/${eventId}`).pipe(
            map((events: any) => {
                return events;
            })
        );
    }

    public resendNotification(eventId: number, data: any): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}/chewbacca/scheduled/resend/${eventId}`, data)
    }
}
