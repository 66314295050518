import {Observable, Subject} from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../../environments/environment';
import { UserDetailsService } from './user-details.service';
import UuidUtil from './../utils/uuid-util';

import * as Faye from 'faye'
import {ChatEventsConstants} from '../../constants/chat-events.constants';
import {CommonEventsConstants} from '../../constants/common-events.constants';



@Injectable()
export class FayeService {
  clientIsOnlineSource = new Subject<any>();
  public client: any;

  private _URL: string = environment.API_SOCKET_URL_CONNECT;
  private _clientId: string;
  private _user: IUser;
  private _token: string;

  private _reconnecSubscription: any;
  private _onMsgSubscription: any;

  constructor(
    private localStorageService: LocalStorageService,
    private userDetailsService: UserDetailsService,
  ) {
    this.connect().catch(err => {
      console.log(`FAYE :: ${err}`);
    });
  }

  private _setClient(_client: any): void {
    this._clientId = _client._dispatcher.clientId;
    this.client = _client;
  }

  private _setExt(): void {
    this.getUserData().then(user => {
      if (user && user.user_account) {
        const uuid = UuidUtil.random(this.localStorageService);
        this.client.addExtension({
          outgoing: function (message, callback) {
            message.ext = message.ext || {};
            message.ext.userId = user.user_account._id;
            message.ext.uuid = uuid;
            callback(message);
          }
        });
      }
    }).catch();
  }

  public getUserData(): Promise<any> {
    return new Promise((resolve, _reject) => {
      const user = this.userDetailsService.userAccessInfo();
      resolve(user);
    });
  }

  public getClientId(): string {
    return this._clientId;
  }

  public getClient(): any {
    return this.client;
  }

  public getUser(): IUser {
    return this._user;
  }

  public disconnect(): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.client.disconnect();
      resolve();
    });
  }

  public sendTyping(user_id, chat_type, chat_id, full_name): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.client.publish('/typing', {user_id, chat_type, chat_id, full_name});
      resolve();
    });
  }

  public onHubEntering(hub_id): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.client.publish(CommonEventsConstants.HUB_ENTER, {hub_id});
      resolve();
    });
  }

  public onHubLeaving(hub_id): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.client.publish(CommonEventsConstants.HUB_EXIT, {hub_id});
      resolve();
    });
  }

  public onChatEntering(user_id, chat_type, chat_id, full_name): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.client.publish(ChatEventsConstants.ENTER, {user_id, chat_type, chat_id, full_name});
      resolve();
    });
  }

  public onChatLeaving(chat_type, chat_id): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.client.publish(ChatEventsConstants.EXIT, {chat_type, chat_id});
      resolve();
    });
  }

  public reconnect(): void {
    if (this._reconnecSubscription) { this._reconnecSubscription.cancel(); }
    console.log('FAYE :: the client is reconnect');
    this._reconnecSubscription = this.client.subscribe('/reconnect');
    this._onMsgSubscription = this.client.subscribe('/message@send', () => { });
    this._setExt();
  }

  public connect(): Promise<any> {
    return new Promise((resolve, reject) => {
      const userAccount = this.userDetailsService.userAccessInfo();
      this._token = userAccount ? userAccount.access_token : null;
      if (!this._token) {
        reject('Token not found');
      }
      if (this.client) {
        this.client.disconnect();
      }
      this.client = new Faye.Client(`${this._URL}faye`, {
        retry: 5, // Retry interval
        timeout: 20 // Timeouts
      });

      this.client.disable('autodisconnect');

      this._setExt();

      this.client.on('transport:down', () => {
        console.log('FAYE :: the client is offline');
        if (this._reconnecSubscription) { this._reconnecSubscription.cancel(); }
        if (this._onMsgSubscription) { this._onMsgSubscription.cancel(); }
      });

      this.client.on('transport:up', () => {
        console.log('FAYE :: the client is online');
        this.clientIsOnlineSource.next('online');
        this._setClient(this.client);
        setTimeout(() => this.reconnect());
      });

      return resolve();

    });
  }
}

interface IUser {
  uuid: string
  token: string
  userId: string
}
