
import {map} from 'rxjs/operators';
import { ChatsService } from './chats.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';
import { Observable } from 'rxjs';



@Injectable()
export class RolesService {
    constructor(
        private http: HttpMiddleService,
        private chatsService: ChatsService
    ) { }

    public readHubRoles(hubId: string): Observable<IRole[]> {
        return this.http.get(`${environment.API_HTTP_URL}roles/hub/${hubId}`).pipe(
        map((roles: any) => {
            return roles;
        }));
    }

    public readUserRoles(hubId: string): Observable<IRole[]> {
        return this.http.get(`${environment.API_HTTP_URL}roles/user/hub/${hubId}`).pipe(
        map((roles: any) => {
            return roles;
        }));
    }

    public readRolesByUser(hubId, userId): Observable<IRole[]> {
        return this.http.get(`${environment.API_HTTP_URL}roles/roles/user/${userId}/hub/${hubId}`).pipe(map((roles: any) => {
            return roles;
        }));
    }

    public readNodesRole(roleId: number): Observable<string[]> {
        return this.http.get(`${environment.API_HTTP_URL}roles/nodes/role/${roleId}`).pipe(map((roles: any) => {
            return roles;
        }));
    }

    public readRole(roleId: number): Observable<IRole> {
        return this.http.get(`${environment.API_HTTP_URL}roles/role/${roleId}`).pipe(map((role: any) => {
            return role;
        }));
    }
    public readOrgRoles(orgId: string): Observable<IRole[]> {
        return this.http.get(`${environment.API_HTTP_URL}roles/user/org/${orgId}`).pipe(map((roles: any) => {
            return roles;
        }));
    }

    public addUserToRoles(roleIds, userId): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}roles/manage/roles/user/${userId}`,
            {
                roles: {
                    roleIds
                }
            }).pipe(map(roles => {
                this.chatsService.updateStreamChats(roleIds, [userId]).subscribe();
                this.chatsService.updateRoleChats(roleIds).subscribe();
                return roles;
            }));
    }

    public removeRolesFromUser(roleIds, userId): Observable<any> {
        const params = { roles: JSON.stringify({ roleIds }) };
        return this.http.delete(`${environment.API_HTTP_URL}roles/manage/roles/user/${userId}`,
            params).pipe(map(response => {
          this.chatsService.updateStreamChats(roleIds, [userId]).subscribe();
          this.chatsService.updateRoleChats(roleIds).subscribe();
        return response;
      }));
    }

    public updateRolesForUser(roleIds, hub_id, userId): Observable<any> {
        const params = {
            roles: roleIds,
            hub_id
        };
        return this.http.put(`${environment.API_HTTP_URL}roles/manage/roles/user/${userId}`,
            params).pipe(map(response => {
            this.chatsService.updateStreamChats(roleIds, [userId]).subscribe();
            this.chatsService.updateRoleChats(roleIds).subscribe();
            return response;
        }));
    }

    public readRolesForUser(hubId: string): Observable<IRole[]> {
        return this.http.get(`${environment.API_HTTP_URL}roles/user/role/hub/${hubId}`).pipe(map((roles: any) => {
            return roles;
        }));
    }

    public readNodeRoles(nodeId: string): Observable<number[]> {
        return this.http.get(`${environment.API_HTTP_URL}roles/add/node/${nodeId}`).pipe(
        map((res: any) => {
            return res.roles;
        }));
    }

    public addNodeToRoles(nodeId: string, roleIds: number[], hubId: string): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}roles/add/node/${nodeId}`, { roleIds, nodeId, hubId }).pipe(map(roles => {
            return roles;
        }));
    }

    public getUsersByRole(roleId: number): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}roles/users/role/${roleId}`).pipe(map(roles => {
            return roles;
        }));
    }

    public getAllUsersByRole(org_id: number): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}yoda/user/organization/${org_id}/all/ids`).pipe(map(roles => {
            return roles;
        }));
    }

    public createRole(role, users, nodes, _usersToUpdate) {
        return this.http.post(`${environment.API_HTTP_URL}roles/role/`, {
            role,
            users,
            nodes
        }).pipe(map(response => {
            return response;
        }));
    }

    public updateRole(role, users, nodes, usersToUpdate) {
        return this.http.put(`${environment.API_HTTP_URL}roles/role/${role.id}`, {
            role,
            users,
            nodes
        }).pipe(map(response => {
            if (usersToUpdate && usersToUpdate.length) {
                this.chatsService.updateStreamChats([role.id], usersToUpdate).subscribe();
            }
            return response;
        }));
    }

    public removeRole(roleId, _enableChat): Observable<any> {
        return this.http.delete(`${environment.API_HTTP_URL}roles/role/${roleId}`).pipe(map((response: any) => {
            const data = response;
            this.chatsService.removeRole({
                chatsIds: data.chatsIds,
                streamsIds: data.streamsIds
            }).subscribe();
            return data;
        }));
    }

    public getRoleCommunicatePermissions(roleId: number): any {
        return this.http.get(`${environment.API_HTTP_URL}roles/communicate/role/${roleId}`).pipe(map(roles => {
            return roles;
        }));
    }

    public readRolesForComunicate(orgId: string): any {
        return this.http.get(`${environment.API_HTTP_URL}roles/communicate/org/${orgId}`).pipe(map(roles => {
            return roles;
        }));
    }

    public setRoleCommunicatePermissions(orgId: string, roleId: number, data): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}roles/communicate/org/${orgId}`,
         { orgId: orgId, data: data, roleId: roleId }).pipe(map((roles: any) => {
            return roles;
        }));
    }
}

export interface IPermissions {
    change_hub_permissions:     1 | 0 | boolean;
    change_node_order:          1 | 0 | boolean;
    change_node_permissions:    1 | 0 | boolean;
    communicate:                1 | 0 | boolean;
    create_nodes:               1 | 0 | boolean;
    create_nodes_in_generic:    1 | 0 | boolean;
    delete_nodes:               1 | 0 | boolean;
    delete_nodes_in_generic:    1 | 0 | boolean;
    edit_nodes:                 1 | 0 | boolean;
    edit_nodes_in_generic:      1 | 0 | boolean;
    enable_group_private_chat:  1 | 0 | boolean;
    enable_role_chat_creation?: 1 | 0 | boolean;
    enable_hub_chat:            1 | 0 | boolean;
    hub_customization:          1 | 0 | boolean;
    include_root:               1 | 0 | boolean;
    invite_users:               1 | 0 | boolean;
    read_hub_roles:             1 | 0 | boolean;
    role_type:                  'custom' | 'admins' | 'users';
    send_alert_message:         1 | 0 | boolean;
}

export interface IRole extends IPermissions {
    id:         number;
    name:       string;
    hub_id:     string;
    createdAt:  string;
    updatedAt:  string;
    nodes_count:    number | string;
    selected?: boolean;
}
