import { AuthorizationService } from './authorization.service';
import { Injectable } from '@angular/core';

import { Observable, Subject, interval as IntervalObservable } from 'rxjs';
import { map, switchMap, distinctUntilChanged, catchError, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';
import { UserDetailsService, IUserAccessInfo } from './user-details.service';
import { AnalyticsService } from './analytics.service';
import { ProfileEventsConstants } from './../../constants/profile-events.constants';
import { FayeService } from './faye.service';
import ServerTime from './../utils/server-time';
import { SessionStorageService } from './session-storage.service';
import { RolesService } from './roles.service';
import { ChatsService } from './chats.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    public updateProfileAnnounced$: Observable<IProfile>;
    public updateUserAccountAnnounced$: Observable<any>;

    private _updateProfileAnnouncedSource = new Subject<IProfile>();

    private _error = new Subject<any>();

    constructor(
        private http: HttpMiddleService,
        private userDetailsService: UserDetailsService,
        private fayeService: FayeService,
        private analyticsService: AnalyticsService,
        private sessionStorageService: SessionStorageService,
        private authorizationService: AuthorizationService,
        private rolesService: RolesService,
        private chatsService: ChatsService

    ) {
        this.updateProfileAnnounced$ = this._updateProfileAnnouncedSource.asObservable();
        this.updateUserAccountAnnounced$ = IntervalObservable(10000).pipe(
            switchMap(() => {
                const userId = this.getUserid();
                return this.read(userId, true);
            }),
            distinctUntilChanged((a, b) => {
                return JSON.stringify(a) === JSON.stringify(b);
            }),
            map((userAccount: any) => {
                const accessInfo = this.sessionStorageService.getObject('access_info') as IUserAccessInfo;
                if (accessInfo && accessInfo.user_account) {
                    const hubs = _.cloneDeep(userAccount.hubs);
                    delete userAccount.hubs;
                    accessInfo.user_account = userAccount;
                    accessInfo.hubs = hubs;
                    this.sessionStorageService.setObject('access_info', accessInfo);
                    this.sessionStorageService.itemSet('access_info');
                }
            }),
            catchError(() => {
                this.authorizationService.logout();
                return this._error;
            })
        );
    }

    public get(_userId?): Observable<IProfile> {
        return this.http.get(`${environment.API_HTTP_URL}yoda/user/${_userId ? _userId : this.getUserid()}`).pipe(
            map((profile: any) => {
                const _profile = profile,
                    st = new ServerTime();

                st.time = _profile['serverTime'];
                return _profile;
            })
        );
    }

    public create(userData: IProfile, hubId): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}yoda/user/create`, { hubId, userData }).pipe(
            mergeMap(profile => this.rolesService.readRolesByUser(hubId, profile.user).pipe(map(roles => {
                return {profile: profile, roles: roles}
            }))),
            map(
                ({profile, roles})=>{
                    if (roles && roles.length){
                        var roleIds: number[] = [];
                        roles.forEach(function(role) {
                            if (role.enable_group_private_chat) {
                                roleIds.push(parseInt(role.id.toString(), 10));
                            }
                        });
                        return {profile: profile, roleIds: roleIds.length ? roleIds : []};
                    }
                }
            ),
            mergeMap(({profile, roleIds}) => this.chatsService.updateRoleChats(roleIds).pipe(map(()=> profile)))
        );
    }

    public update(data: any, userId = null, hubId = null): Observable<IProfile> {
        if (hubId) {
            data['hubId'] = hubId;
        }
        return this.http.put(`${environment.API_HTTP_URL}yoda/user/${userId || this.getUserid()}`, data).pipe(
            map((profile: any) => {
                const profileJson = profile;
                if (!userId && profileJson && profileJson.user_account && profileJson.user_account._id) {
                    this.analyticsService.createEvent({
                        event_type: 'update',
                        resource_id: profileJson.user_account._id,
                        resource_type: 'profile'
                    });
                }
                if (!userId) {
                    this._updateProfileAnnouncedSource.next(profileJson);
                }
                return profileJson;
            })
        );
    }

    public delete(userId): Observable<any> {
        return this.http.delete(`${environment.API_HTTP_URL}kenobi/user-account/${userId}`).pipe(map(response => response));
    }

    public changeDefaultHub(hub_id: string): Observable<any> {
        return this.http.put(`${environment.API_HTTP_URL}kenobi/user-account/${this.getUserid()}/default-hub`, { hub_id }).pipe(
            map(res => {
                return res;
            })
        );
    }

    public getDefaultHub(): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}kenobi/user-account/${this.getUserid()}/default-hub`).pipe(
            map(hub => {
                return hub;
            })
        );
    }

    public updatePassword(oldPassword: string, newPassword: string): Observable<any> {
        return this.http
            .put(`${environment.API_HTTP_URL}kenobi/password`, {
                oldPassword,
                newPassword
            })
            .pipe(map(data => data));
    }

    public onProfileUpdate(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ProfileEventsConstants.UPDATE, function(res) {
                observer.next(res);
            });
        });
    }

    public getUsers(orgID, query): Observable<any> {
        const data = { search: query };
        if (orgID) {
            data['org_id'] = orgID;
        }
        return this.http.get(`${environment.API_HTTP_URL}kenobi/super-admin-users/`, data);
    }

    public readUser(userID): Observable<IProfile> {
        return this.http.get(`${environment.API_HTTP_URL}kenobi/user-account/${userID}`);
    }

    private read(userId, withHubs = false): Observable<any> {
        const _params = {};
        if (withHubs) {
            _params['with_hubs'] = true;
        }
        return this.http.get(`${environment.API_HTTP_URL}kenobi/user-account/${userId}`, _params).pipe(map(response => response));
    }

    private getUserid(): string {
        return this.userDetailsService.userAccessInfo().user_account ? this.userDetailsService.userAccessInfo().user_account._id : '';
    }
}

export interface IProfile {
    _id?: string;
    email: string;
    email_secondary?: string;
    fax_number?: string;
    first_name?: string;
    prefix?: string;
    suffix?: string;
    title?: string;
    department_group?: string;
    pager_number?: string;
    website?: string;
    notes?: string;
    keywords?: string;
    home_number?: string;
    icon?: string;
    icon_min?: string;
    last_name: string;
    mobile_number?: string;
    office_number?: string;
    other_number?: string;
    address?: IAddress;
    primary_number?: string;
    serverTime?: string;
    default_hub?: any;
    createdAt?: string;
    updatedAt?: string;
    defaultColor: string;
    username: string;
}

export interface IAddress {
    zipcode?: number;
    city?: string;
    state?: string;
}
