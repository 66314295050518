export class GoogleDriveConstants {
    public static readonly DEVELOPER_KEY:   string      = 'AIzaSyDmXVZsh4JULoWNcrUy5UOjsmHMLYBwwS4';
    public static readonly CLIENT_ID:       string      = '746432277654-g41thd5j8g4qogjgj13aa5id0oktns6k.apps.googleusercontent.com';
    public static readonly SCOPE:           string[]    = ['https://www.googleapis.com/auth/drive', 'https://www.googleapis.com/auth/drive.readonly', 'https://www.googleapis.com/auth/activity'];
    public static readonly EXPORT:          Object      = {
        "application/vnd.google-apps.document": {
            export_type:   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ext:   "docx"
        },
        "application/vnd.google-apps.presentation": {
            export_type:   "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            ext:   "pptx"
        },
        "application/vnd.google-apps.spreadsheet": {
            export_type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ext:   "xlsx"
        }}
}
