import { Injectable }             from '@angular/core';
import { SessionStorageService }  from './session-storage.service';
import { IHub }                   from './hubs.service';
import { IProfile }               from './profile.service';

@Injectable()
export class UserDetailsService {

  constructor(
    private sessionStorageService: SessionStorageService
  ) { }

  public userAccessInfo(): IUserAccessInfo {
    return this.sessionStorageService.getObject('access_info');
  }

  /**
   * Check if user in Organization
   * @param {String} orgId 
   * @returns {Boolean}
   */
  public isUserInOrganisation(orgId: string): boolean {
    let userAccount, userOrganizations;

    userAccount =  this.userAccessInfo();
    if (!userAccount || !userAccount.user_account) {
      return false;
    };
    userOrganizations = userAccount.user_account.organizations;
    return userOrganizations.some(org => org._id === orgId);
  }
  
  /**
   * Check if user in Hub
   * @param {String} hubId 
   * @returns {Boolean}
   */
  public isUserInHub(hubId: string): boolean {
    let userAccount, userHubs;

    userAccount = this.userAccessInfo();
    if (!userAccount || !userAccount.hubs) {
      return false;
    };
    userHubs = userAccount.hubs;
    return userHubs.some(hub => hub._id === hubId);
  }

  /**
   * Check if curent user is super admin
   * @returns {Boolean}
   */
  public isSuperAdmin(): boolean {
    const userAccount = this.userAccessInfo();
    if (!userAccount || !userAccount.user_account) {
      return false;
    };
    return !!userAccount.user_account.super_admin;
  }
  /**
   * Check if curent user is super admin
   * @returns {Boolean}
   */
  public canCreateAlerts(): boolean {
    const userAccount = this.userAccessInfo();
    if (!userAccount) {
      return false;
    };
    return !!userAccount.isCanCreateAlert;
  }

  /**
   * Check if curent user is organization admin
   * @returns {Boolean}
   */
  public isOrgAdmin(): boolean {
    const userAccount = this.userAccessInfo();
    if (!userAccount || !userAccount.user_account) {
      return false;
    };
    return !!userAccount.user_account.org_admin;
  }

  /**
   * Check if curent has default password
   * @returns {Boolean}
   */
  public isDefaultPassword(): boolean {
    const userAccount = this.userAccessInfo();
    if (!userAccount || !userAccount.user_account) {
      return false;
    };
    return !!userAccount.user_account.default_password;
  }

  /**
   * Check if HubCom enabled in Hub
   * @param {String} hubId 
   * @returns {Boolean}
   */
  public isHubcomEnabled(hubId: string): boolean {
    const userAccount = this.userAccessInfo();
    if (!userAccount || !userAccount.hubs) {
      return false;
    };
    return userAccount.hubs.some(hub => hub._id === hubId);
  }
}

export interface ISecuritySettings {
  biometricTimeout:   number;
  passwordExpiration: number;
  passwordTimeout:    number;
  reAuth:             number;
  webAppTimeout:      number;
  webAppInActive:     number;
  webAppAlert:        number;
}

export interface IOrganization {
  _id:                string;
  default_permission: string;
  description:        string;
  name:               string;
  securitySettings:   ISecuritySettings;
  sounds:             any;
  users:              string[];
  createdAt:          string;
  updateAt:           string;
}

export interface IUserAccessInfo {
  access_token: string;
  hubs:         IHub[];
  user_account: {
    _id:                      string;
    activated:                boolean;
    blocked:                  boolean;
    createdAt:                string;
    default_hub?:             IHub;
    default_password:         boolean;
    login:                    string;
    org_admin:                boolean;
    organizations:            IOrganization[];
    password:                 string;
    password_expiration_date: string;
    super_admin:              boolean;
    updatedAt:                string;
    user:                     IProfile;
  },
  isCanCreateAlert: boolean
}
