import { Injectable } from '@angular/core';

import { from as observableFrom, Observable, Subject, throwError, empty } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';
import { IPermissions } from './roles.service';
import { AnalyticsService } from './analytics.service';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';

export const XSkipInterceptor = 'X-Skip-Interceptor';

@Injectable()
export class NodesService {

    public isNodeUploading = false;
    private linkedState$: Subject<boolean> = new Subject();
    private hubUpdate$: Subject<any> = new Subject();
    constructor(
        private httpClient: HttpClient,
        private http: HttpMiddleService,
        public  handler: HttpBackend,
        private analyticsService: AnalyticsService
    ) {}

    public changeLinkedState(value: boolean): void {
        this.linkedState$.next(value);
    }

    public linkedStateChanged(): Observable<boolean> {
        return this.linkedState$.asObservable();
    }

    public hubUpdate(value: any): void {
        this.hubUpdate$.next(value);
    }

    public hubUpdated(): Observable<any> {
        return this.hubUpdate$.asObservable();
    }

    public getAllUsersSvg () {
        return this.httpClient.get(`${environment.API_HTTP_URL}documents/icons`).pipe(
            tap<string[]>(res => res),
            catchError(error => {
                console.error(error);
                return throwError(error);
            })
        );
    }
    public uploadNewIcon(files: FileList): Observable<any> {
        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append(file.name, file)
        });
        return this.httpClient.post(`${environment.API_HTTP_URL}documents/icons`,formData).pipe(
            catchError(error => {
                console.error(error);
                return throwError(error);
            })
        );
    }
    public deleteIcon(iconUrl: string): Observable<any> {
        const options = { body: { iconUrl: iconUrl } };
        return this.httpClient.request('delete', `${environment.API_HTTP_URL}documents/icons`, options).pipe(
            catchError(error => {
                console.error(error);
                return throwError(error);
            })
        );
    }

    public getStream(streamId: number | string): Observable<INode> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/nodes/read/stream/${streamId}`).pipe(
            map((nodes: any) => {
                return nodes;
            }));
    }

    public getAllParentNodes(nodeIds, hubId, onlyGenerics = false): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/node/generic/parents`, {
            nodeIds,
            hubId,
            onlyGenerics
        }).pipe(map(nodes => nodes));
    }

    public getNodesParents(nodeIds, hubId): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/nodes/read/parents`, {
            nodeIds,
            hubId
        }).pipe(map(nodes => {
            return nodes;
        }));
    }

    public getAllNodesByHub(hubId: String): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/hub/${hubId}/nodes`)
        .pipe(map(nodes => {
            return nodes;
        }));
    }
    public getParentsNodes(nodeIds, hubId): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/nodes/read/nodes_parents`, {
            nodeIds,
            hubId
        }).pipe(map(nodes => {
            return nodes;
        }));
    }

    public getRoot(hubId: string): Observable<INode[]> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/hub/${hubId}/node`).pipe(
            map((nodes: any) => {
                return nodes;
            }));
    }

    public getOne(nodeId: string, hub_id: string): Observable<IOneNode> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/node/${nodeId}`, { hub_id }).pipe(
            map(nodes => {
                return nodes;
            }), catchError((_err) => {
                return throwError(_err);
            }));
    }

    public getSignedNode(nodeId: string): Observable<any> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/node/${nodeId}/sign`).pipe(
            map(response => response),
            catchError(_err => observableFrom([{ node: null }])), );
    }

    public getRootForUser(hubId: string, userId: string, skip?): Observable<INode[]> {
        const headers = skip ? new HttpHeaders().set(XSkipInterceptor, '') : {};

        return this.httpClient.get(`${environment.API_HTTP_URL}chewbacca/user_nodes/${userId}/hub/${hubId}/node`, { headers }).pipe(
            map((nodes: any) => {
                return nodes;
            }));
    }

    public getOneForUser(nodeId: string, hub_id: string, userId: string): Observable<IOneNode> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/user_nodes/${userId}/node/${nodeId}`, { hub_id }).pipe(
            map(nodes => {
                return nodes;
            }), catchError((_err) => {
                return observableFrom([]);
            }));
    }

    public getSharedNodes(hub_id: string): Observable<INode[]> {
        return this.http.get(`${environment.API_HTTP_URL}chewbacca/shared/node`,
            { hub_id }).pipe(map(nodes => nodes), catchError((_err) => {
                return observableFrom([]);
            }), );
    }

    public delete(nodeId: string, hubId: string, parentId: string): Observable<any> {
        const params = {
            hub_id: hubId
        };
        if (parentId) {
            params['parent_id'] = parentId;
        }
        return this.http.delete(`${environment.API_HTTP_URL}chewbacca/node/${nodeId}`, params).pipe(map((res: any) => {
            if (nodeId) {
                this.analyticsService.createEvent({
                    event_type: 'delete',
                    resource_id: nodeId,
                    resource_type: 'node'
                });
            }
            return res;
        }));
    }

    public create(data: IManageNode): Observable<INode> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/node`, data).pipe(map((res: any) => {
            const node = res;
            if (node && node.node && node.node._id) {
                this.analyticsService.createEvent({
                    event_type: 'create',
                    resource_id: node.node._id,
                    resource_type: 'node'
                });
            }
            return node;
        }));
    }

    public createSharedNodes(nodeId: string, hubId: string, parent_id: string = null): Observable<INode> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/shared/node`, { nodeId, hubId, parent_id });
    }

    public createWithFile(data: IManageNode, file: File): Observable<any> {
        this.isNodeUploading = true;
        return this.http.upload(`${environment.API_HTTP_URL}chewbacca/node`, [file], data).pipe(
        tap(_data => {
            this.isNodeUploading = false;
            return _data;
        }),
        catchError(_err => {
            this.isNodeUploading = false;
            return empty();
        })
    );
    }

    public update(nodeId: string, data: IManageNode): Observable<INode> {
        return this.http.put(`${environment.API_HTTP_URL}chewbacca/node/${nodeId}`, data).pipe(map((res: any) => {
            const node = res;
            if (node && node._id) {
                this.analyticsService.createEvent({
                    event_type: 'update',
                    resource_id: node._id,
                    resource_type: 'node'
                });
            }
            return node;
        }, error => {
            console.log(error);
        }));
    }

    public updateWithFile(nodeId: string, data: IManageNode, file: File): Observable<any> {
        return this.http.upload(`${environment.API_HTTP_URL}chewbacca/node/${nodeId}`, [file], data, 'PUT');
    }

    // REORDER

    public saveNodeOrder(data: any): Observable<any> {
        if (data.node_id) {
            return this.http.post(`${environment.API_HTTP_URL}chewbacca/node/${data.node_id}/reorder`, data).pipe(map((res: any) => {
                return res;
            }, error => {
                console.log(error);
            }));
        } else if (!data.node_id && data.hub_id) {
            return this.http.post(`${environment.API_HTTP_URL}chewbacca/hub/${data.hub_id}/reorder`, data).pipe(map((res: any) => {
                return res;
            }, error => {
                console.log(error);
            }));
        }
    }

    public changeParenting(data: INode): Observable<any> {
        data.parent_id =  data.parent_id || null;
        const dataToSend = {
            node_id: data._id,
            data: data
        };
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/node/${data._id}/change_parenting`, dataToSend).pipe(map((res: any) => {
            return res;
        }, error => {
            console.log(error);
        }));
    }

    public exportUsersList(id): Observable<any> {
        return this.httpClient.get<any>(`${environment.API_HTTP_URL}yoda/user/role/${id}/download`)
    }

    public convertNumbersToMobile(data: any) {
        if (data.mobile_number) { data.mobile_number = data.mobile_number.toString().replace(/\(|\)|\s|\+|-/g, ''); }
        // if (data.office_number) { data.office_number = data.office_number.replace(/\(|\)|\s|\+|-/g, ''); }
        if (data.office_number) { data.office_number = data.office_number.toString()}
        if (data.other_number) { data.other_number = data.other_number.toString().replace(/\(|\)|\s|\+|-/g, ''); }
        if (data.primary_number) { data.primary_number = data.primary_number.toString().replace(/\(|\)|\s|\+|-/g, ''); }
        if (data.home_number) { data.home_number = data.home_number.toString().replace(/\(|\)|\s|\+|-/g, ''); }
        if (data.fax_number) { data.fax_number = data.fax_number.toString().replace(/\(|\)|\s|\+|-/g, ''); }
        return data;
    }

    public convertNumbersToWeb(data: any) {
        if (data.mobile_number) { data.mobile_number = this.parseNumber(data.mobile_number.toString()); }
        // if (data.office_number) { data.office_number = this.parseNumber(data.office_number); }
        if (data.office_number) { data.office_number = data.office_number.toString()}
        if (data.other_number) { data.other_number = this.parseNumber(data.other_number.toString()); }
        if (data.primary_number) { data.primary_number = this.parseNumber(data.primary_number.toString()); }
        if (data.home_number) { data.home_number = this.parseNumber(data.home_number.toString()); }
        if (data.fax_number) { data.fax_number = this.parseNumber(data.fax_number.toString()); }
        return data;
    }

    public parseNumber(n: string): string {
        if (!n.match(/\(|\)|\s|\+|-/g)) {
            if (n && n.length) {
                if (n.length === 11) {
                    return `+${n[0]} (${n[1]}${n[2]}${n[3]}) ${n[4]}${n[5]}${n[6]}-${n[7]}${n[8]}${n[9]}${n[10]}`;
                } else if (n.length === 10) {
                    return `(${n[0]}${n[1]}${n[2]}) ${n[3]}${n[4]}${n[5]}-${n[6]}${n[7]}${n[8]}${n[9]}`;
                }
            }
        } else {
            return n;
        }
    }
}

export interface IManageNode {
    id?: string;
    hub_id: string;
    parent_id?: string;
    name: string;
    description?: string;
    node_type: 'generic' | 'stream' | 'link' | 'audio' | 'video' | 'image' | 'text' | 'contact' | 'file' | 'tool';
    content: any;
    icon?: string | { color: string; name: string };
    picture?: string;
    gdrive_token?: string;
    shared_mode?: boolean;
    shared_main?: string;
    shared_copy?: string;
    isOuterLink?: boolean;
}

export interface INode {
    _id: string;
    content: any;
    createdAt: string;
    hub_id: string;
    index: number;
    last_post?: string;
    name: string;
    node_type: 'generic' | 'stream' | 'link' | 'audio' | 'video' | 'image' | 'text' | 'contact' | 'file' | 'tool';
    parents: string[];
    parent_id: string;
    permissions: IPermissions;
    picture?: string | null | undefined;
    icon?: { color: string; name: string };
    atatch?: any;
    gdrive_token?: string;
    roles: number[];
    subscribe?: boolean;
    updatedAt: string;
    shared_mode?: boolean;
    shared_main?: string;
    shared_copy?: string;
    creator_id?: string;
    isOuterLink?: boolean;
}

export interface IOneNode {
    child_node?: INode[];
    node: INode;
}
