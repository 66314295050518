export class FilesExtensionsConstants {
   public static readonly EXTENSIONS: Array<IExt> = [
            {extension: 'mpeg', mimetype: 'video/mpeg'},
            {extension: 'mpg',  mimetype: 'video/mpeg'},
            {extension: 'mp4',  mimetype: 'video/mp4'},
            {extension: 'webm', mimetype: 'video/webm'},
            {extension: 'mp3',  mimetype: 'audio/mp3'},
            {extension: 'ogg',  mimetype: 'audio/ogg'},
            {extension: 'wav', mimetype: 'audio/wav'},
            {extension: 'ogg',  mimetype: 'video/ogg'},
            {extension: 'ogv',  mimetype: 'video/ogg'},
            {extension: 'odt',  mimetype: 'application/vnd.oasis.opendocument.text'},
            {extension: 'qt',   mimetype: 'video/quicktime'},
            {extension: 'flv',  mimetype: 'video/x-flv'},
            {extension: '3gp',  mimetype: 'video/3gpp'},
            {extension: '3g2',  mimetype: 'video/3gpp2'},
            {extension: 'avi',  mimetype: 'video/avi'},
            {extension: 'mov',  mimetype: 'video/quicktime'},
            {extension: 'pdf',  mimetype: 'application/pdf'},
            {extension: 'doc',  mimetype: 'application/msword'},
            {extension: 'docx', mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
            {extension: 'potx', mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.template'},
            {extension: 'ppsx', mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'},
            {extension: 'ppt',  mimetype: 'application/vnd.ms-powerpointtd'},
            {extension: 'ppt',  mimetype: 'application/vnd.ms-powerpoint'},
            {extension: 'pptx', mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'},
            {extension: 'rtf',  mimetype: 'application/rtf'},
            {extension: 'txt',  mimetype: 'text/plain'},
            {extension: 'xls',  mimetype: 'application/vnd.ms-excel'},
            {extension: 'xlsx', mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {extension: 'gif',  mimetype: 'image/gif'},
            {extension: 'jpeg', mimetype: 'image/jpeg'},
            {extension: 'jpg',  mimetype: 'image/jpeg'},
            {extension: 'png',  mimetype: 'image/png'},
            {extension: 'svg',  mimetype: 'image/svg'},
            {extension: 'ico',  mimetype: 'image/x-icon'},
            {extension: 'tif',  mimetype: 'image/tif'},
            {extension: 'tiff', mimetype: 'image/tiff'},
            {extension: 'bmp',  mimetype: 'image/bmp'},
            {extension: 'docx', mimetype: 'application/vnd.google-apps.document'},
            {extension: 'pptx', mimetype: 'application/vnd.google-apps.presentation'},
            {extension: 'xlsx', mimetype: 'application/vnd.google-apps.spreadsheet'}
        ];

   public static readonly IMG_EXTENSIONS: Array<IExt> = [
            {extension: 'gif',  mimetype: 'image/gif'},
            {extension: 'jpeg', mimetype: 'image/jpeg'},
            {extension: 'jpg',  mimetype: 'image/jpeg'},
            {extension: 'png',  mimetype: 'image/png'},
            {extension: 'svg',  mimetype: 'image/svg'}
        ];
}

export interface IExt {
    extension:  string;
    mimetype:   string;
}
