import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';

import { Subject } from 'rxjs';

/** A router wrapper, adding extra functions. */
@Injectable({
  providedIn: 'root'
})
export class RouterExtService {

  public loading = new Subject<boolean>();

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(public router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
          this.loading.next(true);
      }
      if (event instanceof NavigationEnd) {
        this.loading.next(false);
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
      if (event instanceof NavigationCancel || event instanceof NavigationError) {
          this.loading.next(false);
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

}
