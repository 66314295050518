export default class UuidUtil {
    static random(LocalStorageService: any) {
      if (LocalStorageService.get('UUID')) { return LocalStorageService.get('UUID'); }

      let d = new Date().getTime();
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          const r = (d + Math.random() * 16 ) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });

      LocalStorageService.set('UUID', uuid);

      return uuid;
    }
    static doSomethingElse(val: string) { return val; }
}
