import { CreateNodesComponent } from './create-nodes.component';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { ConfirmModalService } from './../../../../core/shared/components/confirm-modal/confirm-modal.service';


@Injectable()

export class CanDeactivateCreateNodes implements CanDeactivate<CreateNodesComponent> {

  constructor(
    private confirmModalService: ConfirmModalService
  ) {}

  public canDeactivate(
      component: CreateNodesComponent
  ): Observable<boolean> | Promise<boolean> | boolean {
      if (component.isNodeUploading()) {
      this.confirmModalService
        .openDialog({ message: 'You have uploading file. Please wait until upload will finish.'}, {}, true);
    }
    return !component.isNodeUploading();

  }
}
