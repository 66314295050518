import { from as observableFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { FayeService } from './faye.service';
import { ChatEventsConstants } from '../../constants/chat-events.constants';
import { HttpMiddleService } from './http-middle.service';
import {INewMessage, INewMessageWithChat} from './messages.service';
import { LocalStorageService } from './local-storage.service';
import { BadgeCountService } from '../../dashboard/hub-comm-nav-bar/badge-count.service';
import {MessageEventsConstants} from '../../constants/message-events.constants';

@Injectable({
    providedIn: 'root'
})

export class ChatsService {

    public chatsForForward = [];

    constructor(
        private http: HttpMiddleService,
        private fayeService: FayeService,
        private localStorageService: LocalStorageService,
        private badgeCountService: BadgeCountService
    ) {}

    // HTTP

    public sync(chats: Object, organization_id: string, hub_id: string, updated_at: number = 0): Observable<ISyncChat> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/communicate/web/sync/chats`, {
            chats,
            organization_id,
            hub_id,
            lastUpdate: updated_at
        }).pipe(map((res: any) => {
            this.chatsForForward = [...res.chats.direct, ...res.chats.group, ...res.chats.role];
            return res;
        }));
    }

    public getChat(chat: IChatParams): Observable<IChat> {
        return this.http.get(`${environment.API_HTTP_URL}` +
            `chewbacca/communicate/read/chat/${chat.chat_type}/${chat.chat_id}`, { web: true }).pipe(map((response: any) => {
                const _chat = response;
                _chat.created_at = moment(_chat.created_at).format();
                _chat.updated_at = moment(_chat.updated_at).format();
                return _chat;
            }), catchError((_err) => {
                switch (chat.chat_type) {
                    case 'notification':
                        this.localStorageService.remove('last_notification');
                        break;
                    case 'stream':
                        this.localStorageService.remove('last_stream');
                        break;
                    default:
                        this.localStorageService.remove('last_conversation');
                        break;
                }
                return observableFrom([]);
            }));
    }

    public ifChatCreator({ chat_type, chat_id }): Observable<Boolean> {
        return this.http.get(`${environment.API_HTTP_URL}` +
            `chewbacca/communicate/chat/creator`, { type: chat_type, id: chat_id }).pipe(map((isCreator: any) => {
                return isCreator.isCreator;
            }), catchError((_err) => {
                return observableFrom([]);
            }));
    }

    public unreadCount(hub_id: string, organization_id: string): Observable<IUnreadCounts> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/communicate/messages/count/unread`, {
            organization_id,
            hub_id,
            read_chats: []
        }).pipe(map((chats: any) => {
            return chats;
        }));
    }

    public readRecipients(chat: IChatParams, isRoleChat: boolean = false): Observable<any> {
        const url = isRoleChat ?
            `${environment.API_HTTP_URL}chewbacca/communicate/read/manage_data/chat/${chat.chat_id}` :
            `${environment.API_HTTP_URL}chewbacca/communicate/read/recipients/chat/${chat.chat_id}`;
        return this.http.get(url, { chat_type: chat.chat_type }).pipe(map(recipients => {
            return recipients;
        }));
    }

    // Socket Emits

    public markRead(chat_id: number, chat_type: string): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/read`, { chat_id, chat_type }).pipe(map((response: any) => {
            const messages = response;
            if (messages.length) {
                for (const message of messages) {
                    message.created_at = moment(message.created_at).format();
                    message.updated_at = moment(message.updated_at).format();
                }
            }
            this.badgeCountService.updateBadgeCount();
            return messages;
        }));
    }

    public markReadWholeChat(chat_id: number, chat_type: string): Observable<any> {
        return this.http.delete(`${environment.API_SOCKET_URL}socket/chat/unread`, { chat_id, chat_type });
    }

    public markUnread(chat_id: number, chat_type: string): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/unread`, { chat_id, chat_type });
    }

    public markFavourite(message_id: number, user_id: string): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/message/important`, { message_id, user_id });
    }

    public unmarkFavourite(message_id: number, user_id: string): Observable<any> {
        return this.http.delete(`${environment.API_SOCKET_URL}socket/message/important`, { message_id, user_id });
    }

    public create(chatData: IManageChat | any): Observable<any> {
        chatData['isWeb'] = true;
        if (chatData['file'] && chatData['file'].source !== 'link') {
            return this.http.upload(`${environment.API_SOCKET_URL}socket/chat/create`,
                [chatData['file']], chatData).pipe(map((res: any) => {
                    res.chat.created_at = moment(res.chat.created_at).format();
                    res.chat.updated_at = moment(res.chat.updated_at).format();
                    return res;
                }))
        }
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/create`, chatData).pipe(map((res: any) => {
            res['chat'].created_at = moment(res['chat'].created_at).format();
            res['chat'].updated_at = moment(res['chat'].updated_at).format();
            return res;
        }));
    }

    public update(chatData: IManageChat): Observable<INewChat> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/update`, chatData).pipe(map((res: any) => {
            return res;
        }));
    }

    public remove(chatData: IChatParams): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/remove`, chatData).pipe(map(res => res));
    }

    public updateRoleChats(roleIds: number[]): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/update/role`, { roleIds }).pipe(map((res: any) => {
            return res;
        }));
    }

    public updateStreamChats(roleIds: number[], userIds: string[]): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/update/stream`, { roleIds, userIds }).pipe(map((res: any) => {
            return res;
        }));
    }

    public removeRole(roleData): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/remove/role`,
            roleData).pipe(map((response: any) => {
                return response;
            }));
    }

    public multiRemoveRoles(data): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/remove/multi/role`, data).pipe(map((response: any) => {
            return response;
        }));
    };

    public multiCreateRoles(data) {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/create/multi/role`, data).pipe(map((response: any) => {
            return response;
        }));
    }

    public leaveFromGroup(chat_id: number): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/leave/group`, { chat_id, chat_type: 'group' })
            .pipe(map((res: any) => res));
    }

    public removeStream(chat_id: number): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/remove/stream`, { chat_id }).pipe(map((res: any) => {
            return res;
        }));
    }

    public subscribeToStream(chat_id: number): Observable<IChat> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/subscribe/stream`, { chat_id }).pipe(map((res: any) => {
            return res;
        }));
    }

    public unsubscribeFromStream(chat_id: number): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/unsubscribe/stream`, { chat_id }).pipe(map((res: any) => {
            return res;
        }));
    }

    public removeNotification(chat_id: number): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/remove/notification`,
            { chat_id, chat_type: 'notification' }).pipe(map((res: any) => {
                this.badgeCountService.updateBadgeCount();
                return res;
            }));
    }

    public removeNotificationLocal(chat_id: number): Observable<any> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/remove/local/notification`,
            { chat_id, chat_type: 'notification' }).pipe(map((res: any) => {
                return res;
            }));
    }

    public acknowledge(creator_id: string, chat_id: number, chat_type?: 'notification'): Observable<IAcknowledge> {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/acknowledge/notification`,
            { creator_id, chat_id, chat_type }).pipe(map((res: any) => {
                this.badgeCountService.updateBadgeCount();
                return res;
            }));
    }

    /**
     * Clear chat history
     * @param {Object} chatData
     * @param {number} chatData.id - chat ID
     * @param {string} chatData.type - chat type
     */
    public clearHistory(chatData) {
        return this.http.post(`${environment.API_SOCKET_URL}socket/chat/clear`, chatData).pipe(map(response => response));
    }

    /**
     * Resend alert
     * @param {number} chat_id - chat ID
     */
    public resendAlert(chat_id) {
        return this.http.post(`${environment.API_SOCKET_URL}socket/notification/resend/${chat_id}`, {});
    }


    // Get User Available Recipients

    public getUserCommunicatePermissions(orgId: string, chat_type?: string, search?: string): Observable<any> {
        const req = {};
        if (chat_type) {
            req['chat_type'] = chat_type;
        }
        if (search) {
            req['search'] = search;
        }
        return this.http.get(`${environment.API_HTTP_URL}roles/user/communicate/${orgId}`, req).pipe(
            map(comPermissions => comPermissions));
    }

    public getRecipientsForInviteStream(data: { orgId: string, chatId: number }): Observable<any> {
        return this.http.post(`${environment.API_HTTP_URL}chewbacca/communicate/invite/stream/users`,
            data).pipe(map((res: any) => {
                return res;
            }));
    }


    // Faye Events Listeners

    public onNotificationRemove(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.REMOVE_ALERT, data => {
                this.badgeCountService.updateBadgeCount();
                observer.next(data);
            });
        });
    }

    public onNotificationLocalRemove(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.REMOVE_LOCAL_ALERT, data => {
                this.badgeCountService.updateBadgeCount();
                observer.next(data);
            });
        });
    }

    public onChatCreated(): Observable<IChat> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.CREATE, data => {
                this.badgeCountService.updateBadgeCount();
                data['chat'].created_at = moment(data['chat'].created_at).format();
                data['chat'].updated_at = moment(data['chat'].updated_at).format();
                observer.next(data['chat']);
            });
        });
    }

    public onRemoveStream(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.REMOVE_STREAM, data => {
                this.badgeCountService.updateBadgeCount();
                observer.next(data);
            });
        })
    }

    public onUnsubscribeFromStream(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.UNSUBSCRIBE, data => {
                this.badgeCountService.updateBadgeCount();
                observer.next(data);
            });
        })
    }

    public onChatClearHistory(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.CLEAR_HISTORY, data => observer.next(data));
        });
    }

    public onLeaveFromGroup(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.LEAVE_GROUP, data => observer.next(data));
        });
    }

    public onRemoveChat(): Observable<any> {
        return new Observable(observer => {
            this.fayeService.getClient().subscribe(ChatEventsConstants.REMOVE, data => observer.next(data));
        });
    }

    public getUsersChatPermissions(chat_id: string): Observable<any> {
        return this.http.get(`${environment.API_SOCKET_URL}socket/chat/${chat_id}/write-permission`, {chat_id});
    }

    public updateUsersChatPermissions(chat_id: string, update_data): Observable<any> {
        return this.http.patch(`${environment.API_SOCKET_URL}socket/chat/${chat_id}/write-permission`, {update_data})
    }

}

export interface IChatParams {
    chat_id: number
    chat_type: string
}

export interface IRecipient {
    username?: string;
    defaultColor?: string;
    _id: string;
    first_name?: string;
    prefix?: string;
    suffix?: string;
    icon_min?: string;
    last_name: string;
    email: string;
    acknowledge?: string;
    deletedAt?: string;
    // can_write?: boolean;
}

export interface IStreamNode {
    _id: string;
    content: any;
    hub_id: string;
    index: number;
    name: string;
    node_type: 'stream';
    parents: string[];
    roles: number[];
    createdAt: string;
    updatedAt: string;
}

export interface IChatBase {
    id: number;
    creator: IRecipient;
    creator_id: string;
    hub_id: string;
    organization_id: string;
    icon?: string;
    recipients: IRecipient[];
    created_at: string;
    updated_at: string;
}

export interface IChat extends IChatBase {
    type: 'direct' | 'group' | 'role' | 'stream' | 'notification' | 'new';
    description?: string;
    disabled?: 0 | 1;
    title?: any;
    node?: IStreamNode;
    subscribed?: string;
    icon_png?: string;
    icon_svg_color?: string;
    icon_svg_name?: string;
    file_name?: string;
    file_size?: string;
    file_type?: string;
    file_url?: string;
    file_url_crop?: string;
    invite_chat_id?: string;
    invite_chat_title?: string;
    message?: any;
    acknowledge_disabled?: boolean;
    auto_subscribe?: boolean;
    is_read_only?: boolean;
    skipUpdate?: boolean;
}

export interface IDirectChat extends IChatBase {
    disabled?: 0 | 1;
    is_deleted?: 0 | string;
    message?: string;
    icon?: string;
    defaultColor?: string;
    message_created_at: string;
    message_creator_id: string;
    messages_count: number;
    subscribed: string;
    title: {
        _id: string;
        first_name?: string;
        prefix?: string;
        suffix?: string;
        last_name: string;
        icon_min?: string;
        defaultColor?: string;
    };
    type: 'direct';
    user_id: string;
}

export interface INotificationChat extends IChatBase {
    disabled?: 0 | 1;
    is_deleted?: 0 | string;
    message: string;
    message_created_at: string;
    message_creator_id: string;
    messages_count: number;
    title: {
        _id: string;
        first_name?: string;
        prefix?: string;
        suffix?: string;
        last_name: string;
    }
    file_name?: string;
    file_size?: string;
    file_type?: string;
    file_url?: string;
    file_url_crop?: string;
    invite_chat_id?: string;
    invite_chat_title?: string;
    type: 'notification';
    user_id: string;
    acknowledge_disabled?: boolean;
}

export interface IGroupChat extends IChatBase {
    description?: string;
    disabled?: 0 | 1;
    is_deleted?: 0 | string;
    message?: string;
    message_creator?: string
    message_created_at?: string;
    message_creator_id?: string;
    messages_count?: number;
    subscribed: string;
    title: string;
    type: 'role';
    user_id: string;
}

export interface IStreamChat extends IChatBase {
    description?: string;
    disabled?: 0 | 1;
    is_deleted?: 0 | string;
    message?: string;
    message_creator?: string
    message_created_at?: string;
    message_creator_id?: string;
    messages_count?: number;
    subscribed: string;
    parent_id?: string;
    icon_png?: string;
    icon_svg_color?: string;
    icon_svg_name?: string;
    title: string;
    type: 'stream';
    user_id: string;
}

export interface IRoleChat {
    id: number;
    role_id: number;
    creator_id: string;
    icon?: string;
    description?: string;
    disabled?: 0 | 1;
    hub_id: string;
    is_deleted?: 0 | string;
    message?: string;
    message_creator?: string
    message_created_at?: string;
    message_creator_id?: string;
    messages_count?: number;
    organization_id: string;
    subscribed: string;
    title: string;
    type: 'group';
    user_id: string;
    created_at: string;
    updated_at: string;
}

export interface ISyncChat {
    chats: {
        direct: IDirectChat[];
        group: IGroupChat[];
        notification: INotificationChat[];
        role: IRoleChat[];
        stream: IStreamChat[];
        favorite: any[];
    };
}

export interface IUnreadCount {
    chat_id: number;
    chat_type: 'direct' | 'group' | 'role' | 'stream' | 'notification';
    unread: number;
}

export interface IUnreadCounts {
    messages_count: IUnreadCount[];
}

export interface IManageChat {
    id?: number;
    type: 'direct' | 'group' | 'role' | 'stream' | 'notification';
    title?: string;
    description?: string;
    icon?: string;
    invite_chat_id?: number;
    invite_chat_title?: string;
    recipients?: string[];
    users?: string[];
    roles?: string[];
    fileInfo?: any;
    file?: any;
    role_id?: number;
    hub_id: string;
    organization_id: string;
    recipient_id?: string;
    message?: string;
    acknowledge_disabled?: boolean;
    is_read_only?: boolean;
    is_locked?: boolean;
    is_forwarded?: boolean;
}

export interface INewChat {
    chat: IChat;
    message?: INewMessage;
}

export interface IAcknowledge {
    acknowledge: {
        chat_id: number;
        date: string;
        user_id: string;
    }
}
