
import { throwError as observableThrowError, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpMiddleService {
    public progress$;
    public progressObserver;
    public progress;
    constructor(
        private httpClient: HttpClient,
    ) {
        this.progress$ = Observable.create(observer => {
            this.progressObserver = observer
        }).pipe(share());
    }

    public get(url: string, params: {} = null): Observable<any> {
        const options = {
            params: this.setHttpParams(params)
        };

        return this.httpClient.get(url, options);
    }

    public post(url: string, data: any): Observable<any> {
        return this.httpClient.post(url, data);
    }

    public upload(url: string, files: File[], params: {} = null, method: 'PUT' | 'POST' = 'POST'): Observable<any> {
        const formData: any = new FormData();
        for (const file of files) {
            formData.append('file', file, file.name)
        }

        for (const key of Object.keys(params)) {
            if (typeof params[key] === 'string') {
                formData.append(key, params[key])
            } else {
                formData.append(key, JSON.stringify(params[key]))
            }
        }
        const req = new HttpRequest(method,
            url,
            formData,
            { reportProgress: true });
        return this.httpClient.request(req).pipe(
            filter(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.progress = Math.round(event.loaded / event.total * 100);
                    this.progressObserver.next({ progress: this.progress, id: params ? params['id'] : null });
                } else if (event.type === HttpEventType.Response) {
                    return true;
                }
                return false;
            }),
            map((responce: HttpResponse<Object>) => responce.body),
            catchError(error => observableThrowError(error))
        );
    }

    public put(url: string, data: any): Observable<any> {
        return this.httpClient.put(url, data);
    }

    public patch(url: string, data: any): Observable<any> {
        return this.httpClient.patch(url, data);
    }

    public delete(url: string, params: {} = null): Observable<any> {

        const options = {
            params: this.setHttpParams(params)
        };

        return this.httpClient.delete(url, options);
    }

    setHttpParams(reqOptions: any): HttpParams {
        let params = new HttpParams();
        if (!reqOptions) {
            return params;
        }

        for (const key of Object.keys(reqOptions)) {
            params = params.append(key, reqOptions[key]);
        }
        return params;
    }
}