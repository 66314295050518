import { Injectable }   from '@angular/core';
import { environment }  from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    public  localStorage: any;
    private _prefix: string;
    

    constructor() {
        if (!localStorage) {
            throw new Error('Current browser does not support Local Storage');
        }
        this.localStorage = localStorage;
        this._prefix = environment.APP_NAME;
    }

    public set(key: string, value: string): void {
        this.localStorage[`${this._prefix}_${key}`] = value;
    }

    public get(key: string): string {
        return this.localStorage[`${this._prefix}_${key}`] || false;
    }

    public setObject(key: string, value: any): void {
        this.localStorage[`${this._prefix}_${key}`] = JSON.stringify(value);
    }

    public getObject(key: string): any {
        return JSON.parse(this.localStorage[`${this._prefix}_${key}`] || '{}');
    }

    public remove(key: string): any {
        this.localStorage.removeItem(`${this._prefix}_${key}`);
    }
    
    public clear(): void {
        this.localStorage.clear();
    }
}
