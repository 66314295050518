
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';


import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(
    private http: HttpMiddleService
    ) { }

    public read(id: string = ''): Observable<any> {
      return this.http.get(`${environment.API_HTTP_URL}yoda/organization/${id}`).pipe(map(res => {
        return res;
      }));
    }

    public createAccount(): Observable<any> {
      return this.http.post(`${environment.API_HTTP_URL}kenobi/organization-account/`, {}).pipe(map(res => {
        return res;
      }));
    }

    public create(payload: { _id: string, users: [], name: string, description: string }): Observable<any> {
      return this.http.post(`${environment.API_HTTP_URL}yoda/organization/`, payload).pipe(map(res => {
        return res;
      }));
    }

    public update(id: string, payload: {name: string, description: string }): Observable<any> {
      return this.http.put(`${environment.API_HTTP_URL}yoda/organization/${id}`, payload).pipe(map(res => {
        return res;
      }));
    }

    public delete(id: string = ''): Observable<any> {
      return this.http.delete(`${environment.API_HTTP_URL}yoda/organization/${id}`).pipe(map(res => {
        return res;
      }));
    }

    public readProfilePermissions(id: string = ''): Observable<any> {
      return this.http.get(`${environment.API_HTTP_URL}yoda/organization/profile_permissions/${id}`)
      .pipe(map(res => res));
  }
}
