let serverTimeOffset = 0;

export default class ServerTime {
    get time(): any {
        const date = new Date();
        if (serverTimeOffset !== 0) { date.setTime(date.getTime() + serverTimeOffset); }
        return date;
    }

    toServerTime(_date, to?: string): any {
        const date = new Date(_date);
        const _serverTimeOffset = serverTimeOffset;
        if (to) {
            switch (to) {
                case 'minutes':
                    date.setMilliseconds(0);
                    date.setSeconds(0);
                    // _serverTimeOffset = parseInt((_serverTimeOffset * 0.0001).toFixed(), 10) * 10000
                    break;
                default:
                    break;
            }
        }
        console.log('DEBUG:: serverTimeOffset', _serverTimeOffset);

        if (serverTimeOffset !== 0) {
            date.setTime(date.getTime() + _serverTimeOffset);
        }
        return date;
    }

    toClientTime(_date): any {
        const date = new Date(_date);
        if (serverTimeOffset !== 0) {
            date.setTime(date.getTime() - serverTimeOffset);
        }
        return date;
    }

    set time(responseHeaderDate: any) {
        if (!serverTimeOffset && responseHeaderDate) {
            try {
                const serverTimeMillisGMT = Date.parse(new Date(Date.parse(responseHeaderDate)).toUTCString()),
                    localMillisUTC = Date.parse(new Date().toUTCString());

                serverTimeOffset = serverTimeMillisGMT - localMillisUTC;
            } catch (e) {
                console.error('DEBUG_LOG ERROR SYNC TIME:', e);
                serverTimeOffset = 0;
            }
        }
    }
}
