import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Injectable({
    providedIn: 'root'
})
export class PdfService {
    private pdfMake: pdfMake = pdfMake;

    constructor() {
        this.pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    view(doc): void {
        this.generatePdfDocument(commands.open, doc);
    }

    print(doc): void {
        this.generatePdfDocument(commands.print, doc);
    }

    private generatePdfDocument(command: commands, docDefinition: any): void {
        if (command === 'open') {
            this.pdfMake.createPdf(docDefinition).open();
        } else if (command === 'print') {
            this.pdfMake.createPdf(docDefinition).print();
        }
    }

}

enum commands {
    open = 'open',
    print = 'print'
}
