import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  public sessionStorage: any;
  private _prefix: string;
  private storageSub: Subject<any>;


  constructor() {
    if (!localStorage) {
      throw new Error('Current browser does not support Session Storage');
    }
    this.sessionStorage = localStorage;
    this._prefix = environment.APP_NAME;
    this.storageSub = new Subject<any>();
  }

  public set(key: string, value: string): void {
    this.sessionStorage[`${this._prefix}_${key}`] = value;
  }

  public get(key: string): string {
    return this.sessionStorage[`${this._prefix}_${key}`] || false;
  }

  public setObject(key: string, value: any): void {
    this.sessionStorage[`${this._prefix}_${key}`] = JSON.stringify(value);
  }

  public getObject(key: string): any {
    return JSON.parse(this.sessionStorage[`${this._prefix}_${key}`] || '{}');
  }

  public remove(key: string): any {
    this.sessionStorage.removeItem(`${this._prefix}_${key}`);
  }

  public clear(): void {
    this.sessionStorage.clear();
  }

  public watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  public itemSet(key: string) {
    const item = this.getObject(key);
    return this.storageSub.next(item);
  }

}
