import {Injectable} from '@angular/core'
import {Subject}    from 'rxjs';

@Injectable()
export class AttachmentPreviewService {
   private _attachmentAnnouncedSource = new Subject<any>();
   setAttachmentAnnounced$ = this._attachmentAnnouncedSource.asObservable();

   setAttachmentStatus(value) {
      this._attachmentAnnouncedSource.next(value);
  }
}
