import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';


import { AuthorizationService } from './authorization.service';
import { UserDetailsService } from './user-details.service';
import { ChatsService } from './chats.service';
import { ConfirmModalService } from '../shared/components/confirm-modal/confirm-modal.service';

@Injectable()

export class CanActivateHubComService implements CanActivate {

  constructor(
    private AuthorizationService: AuthorizationService,
    private UserDetailsService: UserDetailsService,
    private router: Router,
    private chatsService: ChatsService,
    private confirmModalService: ConfirmModalService
  ) { }

  private ifCanActivate(route?: ActivatedRouteSnapshot) {
    if (this.UserDetailsService.isSuperAdmin()) {
      return true;
    }
    if (this.UserDetailsService.isDefaultPassword() && route.data.stateName !== 'reset_default_password') {
      this.router.navigate(['reset-default-password']);
      return false;
    }
    if (!this.UserDetailsService.isDefaultPassword() && route.data.stateName === 'reset_default_password') {
      this.router.navigate(['/']);
      return false;
    }
    const { currentOrganization, currentHub } = this.getDataFromRoute(route);
    if (currentOrganization && currentHub) {
      if (!this.UserDetailsService.isUserInOrganisation(currentOrganization)) {
        return false;
      }
      if (!this.UserDetailsService.isHubcomEnabled(currentHub)) {
        return false;
      }
      return true;
    }
    return false;
  }

  private getDataFromRoute(route: ActivatedRouteSnapshot): { currentOrganization: string, currentHub: string } {
    return (route && route.params && route.params.organization_id) ?
      {
        currentOrganization: route.params.organization_id,
        currentHub: route.params.hub_id
      }
      :
      this.getDataFromRoute(route.parent);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    const user = this.UserDetailsService.userAccessInfo();
    if (!this.AuthorizationService.isLogged()) {
      this.router.navigate(['/']);
      return false;
    }
    if (
      route.params && route.params.chat_id && (route.data.stateName === 'edit_conversation' || route.data.stateName === 'edit_stream')
    ) {
      return this.chatsService
        .ifChatCreator({ chat_type: route.params.chat_type ? route.params.chat_type : 'stream', chat_id: route.params.chat_id })
        .toPromise()
        .then(isCreator => {
          if (!user.user_account.super_admin && !user.user_account.org_admin && !isCreator) {
            this.confirmModalService.openDialog({ message: 'You have no permission to edit!'}, {}, true);
            return false;
          }
          return true;
        });
    }
    if (!this.ifCanActivate(route)) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }

}
