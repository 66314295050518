
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpMiddleService } from './http-middle.service';

export interface IEventData {
  event_type: 'click' | 'enter' | 'leave' | 'create' | 'update' | 'delete' | 'change permission' | 'login' | 'logout';
  resource_id: string;
  resource_type: 'organization' | 'hub' | 'node' | 'user' | 'profile';
  enter_time?: number;
  latitude?: string;
  longitude?: string;
  useragent?: string;
}

@Injectable()
export class AnalyticsService {
  private _coords: any = null;
  constructor(
    private http: HttpMiddleService
  ) {
    this._getCurentPosition();
  }

  public createEvent(data: IEventData) {
    data.enter_time = Date.now();
    if (navigator) {
      if (this._coords && this._coords['longitude'] && this._coords['latitude']) {
        data.latitude = this._coords['latitude'];
        data.longitude = this._coords['longitude'];
      }
      data.useragent = navigator.userAgent;
    }
    if (environment['API_ANALYTICS']) {
      this.http.post(`${environment['API_ANALYTICS']}analytics`, data).pipe(map(res => {
        return res;
      })).subscribe();
    }

  }
  private async _getCurentPosition() {
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 3600000
    };
    navigator.geolocation.getCurrentPosition(res => {
      this._coords = res.coords;
    }, _err => {
      return null;
    }, options);
  }
}
