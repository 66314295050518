import { Injectable }               from '@angular/core';
import { FilesExtensionsConstants, IExt } from './../../constants/files-extensions.constants';
import { PatternConstants }         from './../../constants/pattern.constants';
import * as _                       from "lodash";

declare var Dropbox: any;

@Injectable()
export class DropboxPickerService {

  public open(extensions?: IExt[]): Promise<IDropbox> {
    let self = this,
        _extensions = extensions ? extensions : FilesExtensionsConstants.EXTENSIONS;

    return new Promise((resolve, reject) => {
      Dropbox.choose({
        success: function (files) {
          let data = files[0],
            result = {
              name: data.name,
              type: self._extensionToMime(data.name),
              size: data.bytes,
              data: data.link,
              icon: data.icon,
              source: 'link'
            }
          resolve(result);
        },
        cancel: function () {
          reject('Cancel Dropbox');
        },
        linkType: "direct",
        multiselect: false,
        extensions: _.map(_extensions, ext => `.${ext.extension}`)
      });
    });
  }

  private _extensionToMime(fileName) {
      let ext = fileName.match(PatternConstants.FILE_EXTENSIONS);
      if (ext && ext[0]) {
          var file = _.find(FilesExtensionsConstants.EXTENSIONS, (item) => {
              return item.extension === ext[0];
          });
          if (file) {
              return file['mimetype']
          } else {
              return null;
          }
      } else {
          return null;
      }
  }

}

export interface IDropbox {
  name:   string;
  type:   string;
  size:   number;
  data:   string;
  icon:   string;
  source: string;
}
