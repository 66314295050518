import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, empty, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SecurityService } from '../services/security.service';
import { ConfirmModalService } from './components/confirm-modal/confirm-modal.service';

export const XSkipInterceptor = 'X-Skip-Interceptor';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private reloginErrorMessages = ['Unauthorized', 'Please login again to The Hub. Thank You!', 'relogin required'];
    private permissionErrorMessages = ['Permission denied'];
    private nodeErrorMessages = ['Node not found'];
    private versionErrorMessages = ['Your The Hub version is out of date. Please update The Hub!'];
    constructor(
        private securityService: SecurityService,
        private confirmModalService: ConfirmModalService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const copiedReq = req.clone();
        if (req.headers.get(XSkipInterceptor)) {
            const headers = req.headers.delete(XSkipInterceptor);
            return next.handle(req.clone({ headers }));
        }
        return next.handle(copiedReq).pipe(catchError((err) => {
            console.error('ERROR: ', err);
            if (this.isReloginError(err)) {
                this.securityService.reloginRequired();
                return empty();
            } else if (this.isPermissionError(err)) {
                this.securityService.pageNotFound();
                return throwError(err);
            } else if (this.isNodeError(err)) {
                this.confirmModalService.openDialog({ message: 'Node not found' }, {}, true);
                return throwError(err);
            } else if (this.isVersionError(err)) {
                location.reload();
                return empty();
            } else {
                return throwError(err);
            }
        }));
    }

    private isVersionError(err: any) {
        return err.status === 426 && this.versionErrorMessages.includes(err.error);
    }

    private isNodeError(err: any) {
        return err.status === 400 && this.nodeErrorMessages.includes(err.error);
    }

    private isPermissionError(err: any) {
        return err.status === 400 && this.permissionErrorMessages.includes(err.error);
    }

    private isReloginError(err: any) {
        return err.status === 401 && this.reloginErrorMessages.includes(err.error);
    }
}
