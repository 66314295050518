import { AuthorizationService } from './../../../../core/services/authorization.service';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { Observable } from 'rxjs';

import { SelectRolesComponent } from './select-roles.component';
import { ConfirmModalService } from '../../../../core/shared/components/confirm-modal/confirm-modal.service';

@Injectable()

export class CanDeactivateSelectRolesComponent implements CanDeactivate<SelectRolesComponent> {

    constructor(
      private confirmModalService: ConfirmModalService,
      private authorizationService: AuthorizationService
    ) {}

    canDeactivate(
        component: SelectRolesComponent
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (component.ifCanDeactivate ) {
            return true;
        }
        if (!this.authorizationService.isLogged()) {
            return true;
        }
        const message = `You have not saved the permission group assignments;
        Skipping this will leave this node without assigned  permission groups.
        Proceed with skip?`;
        return this.confirmModalService.openDialog({ message }, { width: '500px' }).toPromise().then(isConfirmed => {
            return isConfirmed;
        });
    }
}
