import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from './session-storage.service';
import { LocalStorageService } from './local-storage.service';
import { UserDetailsService } from './user-details.service';
import { FayeService } from './faye.service';
import { environment } from '../../../environments/environment';

declare var Notification: any;

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private _deleyForRelogin = 120; // relogin after page was closed more than 120 seconds
  private _reloginTimeOut: any;
  private _notificationAlertTimeOut: any;
  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private localStorageService: LocalStorageService,
    private userDetailsService: UserDetailsService,
    private fayeService: FayeService
  ) { }

  public set wasRefreshed(time: any) {
    this.localStorageService.set('wasRefreshed', time);
  }

  public get wasRefreshed(): any {
    return this.localStorageService.get('wasRefreshed');
  }

  public isRefreshed(): void {
    if (this.wasRefreshed && (Date.now() - this.wasRefreshed) / 1000 > this._deleyForRelogin) {
      this.reloginRequired(false);
    } else {
      this.localStorageService.remove('wasRefreshed');
      this.checkSecuritySettings();
    }
  }

  public pageNotFound(): void {
    this.router.navigate(['/']);
  }

  public checkSecuritySettings() {
    this.setLastActivity();
  }

  private _spawnNotification (msg?: string) {
    const icon = '/favicon.ico',
      title = 'The Hub',
      options = {
        body: msg ? msg : 'For security purposes, The Hub will automatically log out in one minute unless you give it some attention.' +
        'Simply click over to The Hub to keep the session active.',
        icon: icon
      };
    const n = new Notification(title, options);
    n.onclick = () => {
      window.focus();
      this.setLastActivity();
    };
  }

  public setLastActivity(): void {
    const userAccount = this.userDetailsService.userAccessInfo();
    if (userAccount && userAccount.user_account && !userAccount.user_account.super_admin
      && userAccount.user_account.organizations
      && userAccount.user_account.organizations.length) {
      const securitySettings = userAccount.user_account.organizations[0].securitySettings;
      this.claerIntervals();
      if (securitySettings && securitySettings.webAppInActive) {
        const reloginIn = (securitySettings.webAppInActive * 60 * 1000);
        this._reloginTimeOut = setTimeout(() => {
          this.reloginRequired();
        }, reloginIn);
        let notifyIn = ((securitySettings.webAppInActive - 1) * 60 * 1000);
        if (securitySettings.webAppAlert) {
          notifyIn = ((securitySettings.webAppInActive - securitySettings.webAppAlert) * 60 * 1000);
        }
        this._notificationAlertTimeOut = setTimeout(() => {
          this._spawnNotification();
        }, notifyIn);
      }
    } else {
      this.claerIntervals();
    }
  }

  public claerIntervals() {
    if (this._reloginTimeOut) {
      clearInterval(this._reloginTimeOut);
    }
    if (this._notificationAlertTimeOut) {
      clearInterval(this._notificationAlertTimeOut);
    }
  }

  public reloginRequired(showNotification = true): void {
    const userAccount = this.userDetailsService.userAccessInfo();
    const isLogged = !!(userAccount && userAccount.user_account);
    if (showNotification && isLogged) {
      this._spawnNotification('Your session has timed out. Please login again.');
    }
    this.fayeService.disconnect().then(_res => {
      if (this.localStorageService.getObject('access_info').loginSSO) {
        const ref = window.open(`https://hubspring.auth0.com/v2/logout?` +
          `federated&returnTo=${environment.HOST_NAME.slice(0, -3)}&client_id=bubddeGs45cQV5h6I7U1wVePstaL3fwh`,
          `_blank`, `clearcache=yes`);
        setTimeout(() => {
          ref.close();
        }, 5000);
        localStorage.clear();
        this.sessionStorage.clear();
        this.router.navigate(['/']);
      } else {
        localStorage.clear();
        this.sessionStorage.clear();
        this.router.navigate(['/']);
      }
      this.claerIntervals();
      this.localStorageService.set('lastPath', this.router.url);
      this.router.navigate(['/']);
    });
  }

}
