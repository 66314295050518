import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HubDndService {
  public dndEventData = new BehaviorSubject(null);

  constructor() { }
  setdndEventDataStatus(value) {
    this.dndEventData.next(value);
  }
}
