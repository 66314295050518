import { Injectable } from '@angular/core';
import { Auth0Constants } from './../../constants/auth0.constants';
import { environment } from '../../../environments/environment';

declare var auth0: any;

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  private _auth0: any;

  constructor() {

    this._auth0 = new auth0.WebAuth({
      domain: Auth0Constants.DOMAIN,
      clientID: Auth0Constants.CLIENT_ID,
      callbackURL: environment.HOST_NAME.replace('/#/', '/callback'),
      redirectUri: environment.HOST_NAME.replace('/#/', '/callback'),
      responseType: Auth0Constants.RESPONSE_TYPE
    });
  }

  public authorize(connection = Auth0Constants.CONNECTION): void {
    this._auth0.authorize({
      connection
    });
  }

  public verify(accessToken: string, _email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._auth0.client.userInfo(accessToken, (err, user) => {
        if (user && !err && user.hasOwnProperty('urn:oid:0:9:2342:19200300:100:1:1')) {
          resolve((user['urn:oid:0:9:2342:19200300:100:1:1'].toLowerCase()));
        } else {
          reject();
        }
      });
    });
  }
}
