import { Injectable } from '@angular/core';
import { HttpRequest,
         HttpInterceptor,
         HttpHandler,
         HttpEvent,
         HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UserDetailsService } from '../services';
import { FayeService } from '../services';

export const XSkipInterceptor = 'X-Skip-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private _token: string;
    private _clientId: string;
    constructor(
        private userDetailsService: UserDetailsService,
        private fayeService: FayeService
    ) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const copiedReq = req.clone({headers: this.setAuthorizationHeaders(req.headers)});
        return next.handle(copiedReq);
    }

    private setAuthorizationHeaders(headers) {
        const headerSettings: { [name: string]: string | string[]; } = {};
        this._token = this.userDetailsService.userAccessInfo()
            ? this.userDetailsService.userAccessInfo().access_token
            : '';
        this._clientId = this.fayeService.getClientId()
            ? this.fayeService.getClientId()
            : '';
        if (this._clientId) {
                headerSettings['clientId'] = this._clientId;
        }
        if (this._token) {
            headerSettings['Authorization'] = `Bearer ${this._token}`;
        }
        headerSettings['app-version'] = environment.SEVER_VERSION;
        if (headers.has(XSkipInterceptor)) {
            headerSettings[XSkipInterceptor] = 'true';
        }
        return new HttpHeaders(headerSettings);
    }
}
