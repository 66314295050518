export const environment = {
    production: false,
    envName: 'develop',
    APP_NAME: 'The Hub',
    APP_VERSION: '0.0.1',
    SEVER_VERSION: '3.7.0',
    HUBCOMM_VERSION: '2.5.4',
    API_HTTP_URL: 'https://elhubio-api-dev.scenario-projects.com/api/',
    API_SOCKET_URL: 'https://elhubio-api-dev.scenario-projects.com/',
    API_SOCKET_URL_CONNECT: 'https://elhubio-api-dev.scenario-projects.com/faye/',
    ADMIN_PANEL_URL: 'https://elhubio-admin-dev.scenario-projects.com/',
    // API_ANALYTICS:    'https://stat.hubspringhealth.com/',
    HOST_NAME: 'https://elhubio-dev.scenario-projects.com/#/'
};
