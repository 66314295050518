import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import * as _ from 'lodash';

import { GoogleDriveConstants } from './../../constants/google-drive.constants';
import { FilesExtensionsConstants } from './../../constants/files-extensions.constants';

/**
 * @todo check if neaded
 */
// declare var window:   any;
declare var gapi:     any;
declare var google:   any;

let oauthToken;

@Injectable()
export class GoogleDrivePickerService {

  private readonly developerKey: string = GoogleDriveConstants.DEVELOPER_KEY;

  private _blockedPopUpAnnouncedSource = new Subject<boolean>();
  blockedPopUpAnnounced$ = this._blockedPopUpAnnouncedSource.asObservable();

  public open(isOnlyImage?: boolean): Promise<IGoogleDrive> {

    const self = this;
    let _immediate = false;

    return new Promise((resolve, reject) => {

      gapi.auth.checkSessionState({
        'client_id': GoogleDriveConstants.CLIENT_ID,
        'session_state': null
      }, function (stateMatched) {
        self._blockedPopUpAnnouncedSource.next(stateMatched);
        _immediate = !stateMatched;
        gapi.load('auth', {
          'callback': _authGapiCallback
        });
        gapi.load('picker');
      });

      function _mimeToExtension(mime) {
        return _.find(FilesExtensionsConstants.EXTENSIONS, (item) => {
          return item.mimetype === mime;
        });
      }

      function _authGapiCallback(_data) {
        const authToken = gapi.auth.getToken();
        if (authToken) {
          _authorizeCallback(authToken);
        } else {
          gapi.auth.authorize({
            client_id: GoogleDriveConstants.CLIENT_ID,
            scope: GoogleDriveConstants.SCOPE,
            immediate: _immediate,
            prompt: 'select_account'
          }, _authorizeCallback);
        }
      }

      function _authorizeCallback(res) {
        self._blockedPopUpAnnouncedSource.next(false);
        if (res && !res.error) {
          oauthToken = res.access_token;
          let picker;

          if (isOnlyImage) {
              picker = new google.picker.PickerBuilder().
              addView(google.picker.ViewId.DOCS_IMAGES).
              setOAuthToken(oauthToken).
              setDeveloperKey(self.developerKey).
              setCallback(pickerCallback).
              build();
          } else {
              picker = new google.picker.PickerBuilder().
              addView(google.picker.ViewId.DOCS).
              addView(google.picker.ViewId.DOCS_IMAGES).
              addView(google.picker.ViewId.PDFS).
              addView(google.picker.ViewId.DOCUMENTS).
              addView(google.picker.ViewId.PRESENTATIONS).
              addView(google.picker.ViewId.SPREADSHEETS).
              setOAuthToken(oauthToken).
              setDeveloperKey(self.developerKey).
              setCallback(pickerCallback).
              build();
          }

          picker.setVisible(true);
        } else {
          reject(res);
        }
      }

      function pickerCallback(data) {
        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
          const doc = data[google.picker.Response.DOCUMENTS][0],
            param = { 'fileId': doc.id, 'oAuthToken': oauthToken, 'name': doc.name };

          gapi.client.request({
            'path': `/drive/v2/files/${param.fileId}`,
            'params': { 'maxResults': '1000' },
            callback: requestCallback.bind(self)
          });
        }
      }

      function requestCallback(data, _responsetxt) {
        if (GoogleDriveConstants.EXPORT[data.mimeType]) {
          data.downloadUrl = `${data.exportLinks[GoogleDriveConstants.EXPORT[data.mimeType]['export_type']]}&access_token=${oauthToken}`;
          data.fileExtension = _mimeToExtension(data.mimeType).extension;
        }

        if (data.title.split('.').pop() !== data.fileExtension) {
          data.title = data.title + '.' + data.fileExtension;
        }

        resolve({
          name: data.title,
          type: data.mimeType,
          size: data.fileSize ? data.fileSize : 0,
          data: data.downloadUrl.replace('content.googleapis.com', 'www.googleapis.com'), // using this downloadUrl you will be able to download Drive File Successfully
          gdrive_token: oauthToken,
          ext: data.fileExtension ? data.fileExtension : data.title.split('.').pop(),
          source: 'link',
          thumbnail: data.thumbnailLink
        });
      }

    })
  }

}

export interface IGoogleDrive {
    name:         string;
    type:         string;
    size:         number;
    data:         string;
    gdrive_token: string;
    ext:          string;
    thumbnail:    string;
    source:       string;
}
